import {
  browserProfilingIntegration,
  browserTracingIntegration,
  getCurrentHub,
  init,
  showReportDialog,
} from "@sentry/vue";
import { captureConsoleIntegration } from "@sentry/integrations";
import { Logger } from "~/logging/Logger";
import { LoggerKey } from "~/injectionKeys";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const appConfig = useAppConfig();
  const router = useRouter();

  let logger: Logger;
  if (runtimeConfig.public.sentry.enable) {
    // Important: This is client-side (browser) only.
    // For the server-side setup, see `server/plugins/sentry.ts`.
    init({
      app: nuxtApp.vueApp,
      dsn: runtimeConfig.public.sentry.dsn,
      environment: runtimeConfig.public.sentry.environment,
      release: appConfig.build.release,
      integrations: [
        // See https://docs.sentry.io/platforms/javascript/profiling/
        browserTracingIntegration({ router }),
        browserProfilingIntegration(),
        captureConsoleIntegration({ levels: ["warn", "error"] }),
      ],
      tracesSampleRate: runtimeConfig.public.sentry.tracesSampleRate,
      tracePropagationTargets: [
        "localhost",
        /^\/(?!\/)/,
        runtimeConfig.public.site.url,
        runtimeConfig.public.solr.baseUrl,
        runtimeConfig.public.scripture.baseUrl,
      ],
      profilesSampleRate: runtimeConfig.public.sentry.profilesSampleRate,
      attachProps: true,
      logErrors: false,
    });

    // Unfortunately, Sentry uses globals, so `init` does not return an instance of a Sentry client.
    // We have to get it from the hub.
    const client = getCurrentHub().getClient();

    logger = new Logger(client, showReportDialog);
  } else {
    logger = new Logger(undefined, undefined);
  }

  nuxtApp.vueApp.provide(LoggerKey, logger);
});
