<template lang="pug">
div.verse(:class="classes")
  div.verse__heading(
    v-if="showHeading && (subHeading?.length || heading?.length)"
  )
    div.verse__heading-ref(
      v-if="subHeading?.length"
      v-html="subHeading"
    )
    HH.text-h3.verse__heading-title(
      v-if="heading?.length"
      v-html="heading"
    )
  p.verse__content.text-body-1(:class="{ 'verse__content--dense': dense }")
    span.verse__number(:class="{ 'verse__number--outer': refNumberOuter }") {{ verse.label }}
    | {{ " " }}
    span.verse__text(
      :class="`verse__text--${verse.tid}`"
      v-html="verseText"
    )
    VLazy(
      v-if="showStudyButton"
      tag="span"
    )
      BibleVerseStudyButton.verse__note(:range="verse")
        template(#activator="activatorProps")
          button.verse__noteBtn(
            type="button"
            v-bind="activatorProps['props']"
          )
            VIcon(size="x-small") {{ commentaryOutline }}
</template>

<script setup lang="ts">
/**
 * A component that displays a single Bible verse.
 */

import type { Verse } from "@rsc/scripture-util";
import "~/assets/fonts/SBLHebrew.css";
import { computed } from "vue";
import { commentaryOutline } from "~/icons";
import HH from "~/headings/components/HH.vue";

interface Props {
  /**
   * The Verse object to display.
   */
  verse: Verse;

  /**
   * Whether this verse is the first one in a list of verses.
   */
  first?: boolean;

  /**
   * Whether to show the book and chapter as a verse heading.
   *
   * Available options:
   * - 'auto' (default): When the verse is first in the chapter, or the verse is the first one in the list of verses.
   * - 'never': Never show the chapter and verse as a heading.
   * - 'always': Always show the chapter and verse as a heading.
   */
  showBookAndChapter?: "auto" | "never" | "always";

  /**
   * Whether to show the translation in the heading.
   * Ignored if no heading is shown.
   */
  showTranslation?: boolean;

  /**
   * Whether to show the heading when available.
   */
  showHeading?: boolean;

  /**
   * The words to highlight in this verse.
   */
  selectedWordOrdinals?: number[];

  /**
   * Whether to apply column styling (max-width and auto margins).
   */
  columnStyle?: boolean;

  /**
   * Place reference number on the left, outside the column.
   */
  refNumberOuter?: boolean;

  /**
   * Set a "dense" line-height of text content for some context
   */
  dense?: boolean;

  /**
   * Should display a study button with corresponding comments
   */
  showStudyButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showBookAndChapter: "auto",
  selectedWordOrdinals: () => [],
  showStudyButton: false,
});

/**
 * The heading to display if necessary.
 */
const heading = computed<string>(() =>
  props.verse.makeHeading(true, false, props.showTranslation),
);

const subHeading = computed<string>(() => {
  const showBookAndChapter =
    props.showBookAndChapter === "auto"
      ? props.verse.firstInChapter || props.first
      : props.showBookAndChapter === "always";

  if (showBookAndChapter) {
    return props.verse.makeHeading(false, true, props.showTranslation);
  } else {
    return "";
  }
});

/**
 * The text to display in the verse
 */
const verseText = computed<string>(() =>
  props.verse.getFormattedText(props.selectedWordOrdinals),
);

const classes = computed(() => ({
  [`verse--${props.verse.tid}`]: true,
  "verse--refNumberOuter": props.refNumberOuter,
  "verse--column": props.columnStyle,
}));
</script>

<style lang="scss">
@use "../assets/styles/abstract/fonts" as fo;

.partial-verse::before {
  content: attr(data-label);
  font-size: 0.8em;
  padding-right: 0.8ch;
  font-weight: bold;
  opacity: 0.8;
  font-family: fo.$body;
}
</style>

<style scoped lang="scss">
@use "../assets/styles/abstract/fonts" as fo;
@use "../assets/styles/abstract/functions" as f;

.verse__heading {
  margin-top: f.rem(24);
  margin-bottom: f.rem(12);
}

.verse__heading-ref {
  position: relative;
  font-family: fo.$body;
  font-weight: 700;
  font-size: f.rem(14px);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: f.rem(8);
  color: rgb(var(--v-theme-primary-c700));

  &::before {
    content: "";
    position: absolute;
    bottom: -#{f.rem(2)};
    width: f.rem(24px);
    height: f.rem(2px);
    left: -#{f.rem(4)};
    background-color: rgb(var(--v-theme-primary-c100));
  }

  .v-theme--dark & {
    color: rgb(var(--v-theme-primary-c100));
    &::before {
      background-color: rgb(var(--v-theme-primary-c700));
    }
  }
}

.verse__heading-title {
  letter-spacing: 0.05em !important;
  line-height: 1.4em;
}

.verse__number {
  font-size: 0.8em;
  font-weight: bold;
  opacity: 0.8;
  font-family: fo.$body;
  display: inline-block;
}

.verse__number--outer {
  position: absolute;
  right: calc(100% + #{f.rem(4)});
  top: 0;
}

.verse {
  &--refNumberOuter {
    padding-left: f.rem(8);
  }

  &--column {
    max-width: f.rem(400);
    margin-left: auto;
    margin-right: auto;
    padding-left: f.rem(32);
    padding-right: f.rem(12);
  }
}

.verse__note {
  margin-left: f.rem(4);
  display: inline-flex;
  align-self: center;
  line-height: 1;
  vertical-align: baseline;
}

.verse__noteBtn {
  margin: 0;
  display: inline-flex;
  height: f.rem(20);
  line-height: 1;
  padding: f.rem(2) f.rem(4);
  border-radius: f.rem(4);
  color: rgb(var(--v-theme-on-surface-variant));
  background-color: rgb(var(--v-theme-secondary-c900));
  opacity: 0.4;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover,
  &[aria-expanded="true"] {
    opacity: 1;
  }
  .v-theme--dark & {
    background-color: rgb(var(--v-theme-secondary-c500));
  }
}

.verse .verse__content {
  position: relative;
  margin-bottom: 0;
  line-height: 2em;

  .verse--heb & {
    text-align: right;
    direction: rtl;
    font-family: "SBL Hebrew", serif !important;
  }

  &--dense {
    line-height: 1.7em;
  }
}
</style>
