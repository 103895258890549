import { SolrClient } from "@rsc/vv-solr-client";
import { ScriptureClient } from "@rsc/scripture-client";
import { fetch } from "ofetch";
import { FetchApiWrapper } from "./FetchApiWrapper";
import { ResultAsyncCache } from "./ResultAsyncCache";
import {
  FetchApiWrapperKey,
  ScriptureClientKey,
  SolrClientKey,
} from "~/injectionKeys";

/**
 * Make clients available through `provide`/`inject`.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const fetchApiWrapper = new FetchApiWrapper(
    // We want to use the native fetch both in the browser and in Node (for SSR), rather than Nuxt's `$fetch`, which has
    // a different API.
    fetch.bind(globalThis),
    (input, init) =>
      new Request(input, {
        keepalive: true,
        ...init,
      }),
  );
  const f = fetchApiWrapper.fetch.bind(fetchApiWrapper);

  const scriptureClient = new ScriptureClient(
    // On the server, try to use the server-side base URL, which might be faster than the public one.
    process.server
      ? config.scripture.baseUrl || config.public.scripture.baseUrl
      : config.public.scripture.baseUrl,
    f,
  );

  const solrClient = new SolrClient(
    // On the server, try to use the server-side base URL, which might be faster than the public one.
    process.server
      ? config.solr.baseUrl || config.public.solr.baseUrl
      : config.public.solr.baseUrl,
    f,
    {
      username: config.public.solr.auth.user,
      password: config.public.solr.auth.pass,
    },
    config.public.solr.core,
    new ResultAsyncCache(60000),
  );

  nuxtApp.vueApp.provide(ScriptureClientKey, scriptureClient);
  nuxtApp.vueApp.provide(SolrClientKey, solrClient);
  nuxtApp.vueApp.provide(FetchApiWrapperKey, fetchApiWrapper);
});
