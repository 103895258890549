/**
 * Make a human-readable list of words using commas and ampersand.
 *
 * From https://stackoverflow.com/a/53879103/836995
 *
 * @param items
 *   The array of strings to concatenate into a list.
 * @returns
 *   A single string containing all items.
 */
export function makeListString(items: string[]): string {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0] || "";
  const firsts = items.slice(0, items.length - 1);
  const last = items[items.length - 1];
  return firsts.join(", ") + " & " + last;
}

/**
 * Convert all hyphens and "em dashes" to "en dashes" (or the given character,
 * if specified). It also makes sure to use a character, not an HTML entity.
 *
 * @param str
 *   The string to convert.
 * @param dash
 *   The dash to replace with. Optional. Defaults to "en dash".
 * @return
 *   A string with dashes replaced.
 */
export function replaceDashes(str: string, dash = "–"): string {
  return str.replace(/[-–—]|&#45;|&#8211;|&#8212;|&mdash;|&ndash;/g, dash);
}

export function parseIntWithFallback(value: string, fallback: number): number {
  const result = parseInt(value);
  return isNaN(result) ? fallback : result;
}
