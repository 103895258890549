/**
 * Utilities for working with Vue Router.
 */

import type {
  LocationQuery,
  RouteLocation,
  RouteParams,
  Router,
} from "vue-router";
import {
  dictItemsAdded,
  existingDictItemsChanged,
} from "~/units/objectsCompare";

/**
 * Ignore "NavigationDuplicated" errors.
 *
 * This happens when we try to update the query parameters, when they didn't actually change.
 */
export function ignoreNavigationDuplicated(error: Error): undefined {
  if (error.name !== "NavigationDuplicated") {
    throw error;
  }
  return undefined;
}

/**
 * A function that will update the `query` and/or `params` of the current route.
 *
 * @param to The destination location or route. Only the `query` and `params` are used.
 *
 * @return
 *   The return value from either `push` or `replace`, which should be a promise.
 */
export type UpdateQueryAndParamsFunction = (to: {
  query?: LocationQuery;
  params?: RouteParams;
}) => ReturnType<Router["push"]>;

/**
 * Create a function that will update the current route.
 *
 * - It chooses between `push` and `replace` based on circumstances.
 * - It only updates `query` and/or `params`. It is not meant for general navigation between routes.
 *
 * @param router The router used to perform the route updates.
 *
 * @returns A function that will update the `query` and/or `params` of the current route.
 */
export function createFunctionUpdateQueryAndParams(
  router: Pick<Router, "push" | "replace" | "currentRoute">,
): UpdateQueryAndParamsFunction {
  return (to: {
    query?: LocationQuery;
    params?: RouteParams;
  }): ReturnType<typeof router.push> => {
    const toRoute = {
      query: {
        ...router.currentRoute.value.query,
        ...to.query,
      },
      params: {
        ...router.currentRoute.value.params,
        ...to.params,
      },
    };

    // queryChanged means: One or more of the query parameters that are already in the URL now gets a new value.
    const queryChanged = existingDictItemsChanged(
      router.currentRoute.value.query || {},
      toRoute.query,
    );

    // queryAdded means: One or more of the new query parameters are not yet in the URL.
    const queryAdded = dictItemsAdded(
      router.currentRoute.value.query || {},
      toRoute.query,
    );

    // paramChanged means: One or more of the new URL segments changed. If one were added or removed, we would be on a
    // different named route, so that's irrelevant here.
    const paramChanged = existingDictItemsChanged(
      router.currentRoute.value.params || {},
      toRoute.params,
    );

    if (paramChanged || queryChanged) {
      return router.push(toRoute).catch(ignoreNavigationDuplicated);
    }
    if (queryAdded) {
      return router.replace(toRoute).catch(ignoreNavigationDuplicated);
    }
    // Otherwise do nothing.
    return Promise.resolve(undefined);
  };
}

export function getStringQueryParameter(
  l: Pick<RouteLocation, "query">,
  name: string,
): string | null {
  const value = l?.query?.[name];
  return (Array.isArray(value) ? value[0] : value) ?? null;
}

export function getStringParam(
  l: Pick<RouteLocation, "params">,
  name: string,
): string | null {
  const value = l?.params?.[name];
  return (Array.isArray(value) ? value[0] : value) ?? null;
}
