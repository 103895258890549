/**
 * A pinia store for storing user preferences.
 */
import { defineStore } from "pinia";
import { ref } from "vue";

export type Theme = "auto" | "light" | "dark";

export const usePreferencesStore = defineStore(
  "preferences",
  () => {
    const getInit = () => ({
      // Default to "light" because "auto" is currently broken.
      theme: <Theme>"light",
      doNotInstall: false,
    });
    const init = getInit();

    /**
     * Index of the theme to use:
     */
    const theme = ref<Theme>(init.theme);

    /**
     * Whether the user has denied installing the app. If this is true, we won't ask them again.
     */
    const doNotInstall = ref<boolean>(init.doNotInstall);

    function $reset() {
      const init = getInit();
      theme.value = init.theme;
      doNotInstall.value = init.doNotInstall;
    }

    return {
      theme,
      doNotInstall,
      $reset,
    };
  },
  {
    persist: [
      {
        // IMPORTANT: When using cookie persistence, we should explicitly allow the cookie through CloudFront.
        // See CloudFront → Policies → Cache → VV-CachingOptimized-WithCookies-WithQuery → Edit cache policy → Cache key settings
        storage: persistedState.cookiesWithOptions({
          sameSite: "strict",
        }),
        paths: ["theme"],
      },
      {
        storage: persistedState.localStorage,
        paths: ["doNotInstall"],
      },
    ],
    share: {
      enable: true,
      initialize: false,
    },
  },
);

export type PreferencesStore = ReturnType<typeof usePreferencesStore>;
