<template lang="pug">
// The `z-index` style is a workaround: See https://github.com/vuetifyjs/vuetify/issues/19871
VNavigationDrawer(
  :color="$vuetify.theme.name === 'dark' ? 'primary-c900' : undefined"
  :model-value="menuOpen"
  width="320"
  :temporary="true"
  :order="-1"
  style="z-index: 1100"
  @update:model-value="emit('update:menuOpen', $event)"
)
  TheNavigationDrawerContent
  template(#append)
    div.pa-2
      VBtn(
        :block="true"
        variant="outlined"
        @click="emit('update:menuOpen', false)"
      )
        VIcon {{ mdiClose }}
        | {{ i18n.t("Global.close") }}
</template>

<script setup lang="ts">
import { mdiClose } from "@mdi/js";
import { useI18n } from "vue-i18n";

const i18n = useI18n();

interface Props {
  menuOpen?: boolean;
}

const props = defineProps<Props>();

interface Emit {
  (e: "update:menuOpen", value: boolean): void;
}

const emit = defineEmits<Emit>();
</script>
