import solrResults from "../data/solr/taxonomy/detailLevels.json";
import type { Dict } from "~/types";

export type DetailLevel = {
  /**
   * Name, keyed by language code.
   */
  name: Dict<string>;

  /**
   * Description, keyed by language code.
   */
  description: Dict<string>;

  /**
   * Weight, used for sorting commentary types in the UI.
   */
  weight: number;

  /**
   * Universally unique identifier.
   */
  uuid: string;
};

export const detailLevels: DetailLevel[] = solrResults.grouped.ss_uuid.groups
  .map((group) => {
    const name: Dict<string> = {};
    const description: Dict<string> = {};

    group.doclist.docs.forEach((doc) => {
      switch (doc.ss_search_api_language) {
        case "en":
          name.en = doc.tm_X3b_en_name?.[0] ?? "";
          description.en = doc.zs_description_plain[0] ?? "";
          break;
        case "fr":
          name.fr = doc.tm_X3b_fr_name?.[0] ?? "";
          description.fr = doc.zs_description_plain[0] ?? "";
          break;
      }
    });

    const doc = group.doclist.docs[0];
    if (!doc) {
      throw new Error(`No doc for commentary type ${group.groupValue}`);
    }

    return {
      name,
      description,
      weight: doc?.its_weight,
      uuid: group.groupValue,
    };
  })
  .sort((a, b) => a.weight - b.weight);

/**
 * The number of dots to display for each detail level. Keyed by detail level weight.
 */
export const numDotsPerDetailLevelWeight = detailLevels.reduce(
  (result: Dict<number>, level, i) => {
    result[level.weight] = i + 1;
    return result;
  },
  {},
);

export function getDefaultDetailLevel(): DetailLevel {
  const config = useRuntimeConfig();
  return requireDetailLevel(config.public.commentary.defaultDetailLevelWeight);
}

export function getDetailLevelFromWeightWithFallback(
  weight: number,
): DetailLevel {
  // Assume that no two levels of detail have the same weight.
  return (
    detailLevels.find((level) => level.weight === weight) ||
    getDefaultDetailLevel()
  );
}

export function requireDetailLevel(weight: number) {
  const level = findDetailLevel(weight);
  if (!level) {
    throw new Error(`Unknown detail level weight: ${weight}`);
  }
  return level;
}

function findDetailLevel(weight: number) {
  return detailLevels.find((level) => level.weight === weight);
}
