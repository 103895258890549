<template lang="pug">
// The explicit ID is a workaround. See https://github.com/vuetifyjs/vuetify/issues/19696
VSelect#input-select-theme(
  v-model="preferencesStore.theme"
  :items="items"
  :label="i18n.t('Settings.Theme')"
  :color="$vuetify.theme.name === 'dark' ? 'secondary' : 'secondary-c900'"
  variant="outlined"
)
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Capacitor } from "@capacitor/core";
import { usePreferencesStore } from "~/stores/usePreferencesStore";

const i18n = useI18n();
const preferencesStore = usePreferencesStore();

const items = computed(() => [
  {
    value: "light",
    title: i18n.t("Settings.light"),
    subtitle: i18n.t("Settings.light_subtitle"),
  },
  {
    value: "dark",
    title: i18n.t("Settings.dark"),
    subtitle: i18n.t("Settings.dark_subtitle"),
  },
  // Disabled for now on web only, because it causes a strange mix of light and dark mode when doing a hard refresh.
  // See the issues linked in `detectThemeFromRequest`.
  // The "DeviceTheme" plugin only has a native implementation.
  ...(Capacitor.isNativePlatform() && Capacitor.isPluginAvailable("DeviceTheme")
    ? [
        {
          value: "auto",
          title: i18n.t("Settings.auto"),
          subtitle: i18n.t("Settings.auto_subtitle"),
        },
      ]
    : []),
]);
</script>
