/**
 * Translation data from the scripture server.
 *
 * Since this affects the app's routing, it greatly simplifies the code to have this hard-coded here rather than
 * requested from the scripture server asynchronously at runtime.
 */

import type { StandaloneTranslationData } from "@rsc/scripture-model";
import data from "./data/allTranslations.json";
import type { Dict } from "~/types";

/**
 * The IDs of all available translations.
 */
export const availableTids: string[] = Object.keys(data);

/**
 * All translations, keyed by translation ID.
 */
export const allTranslations: Dict<StandaloneTranslationData> = data;

/**
 * All translations as an array.
 */
export const allTranslationsArray: StandaloneTranslationData[] =
  Object.values(data);
