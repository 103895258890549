import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type { Dict } from "~/types";
import { isDefined } from "~/units/typescriptHelpers";

/**
 * A pinia store for the user's reading lists.
 */
export const useReadingListsStore = defineStore(
  "readingLists",
  () => {
    const init = getInit();

    const readingLists = ref<Dict<ReadingList>>(init.readingLists);

    function $reset() {
      const init = getInit();
      readingLists.value = init.readingLists;
    }

    const readingListsNames = computed<string[]>(() =>
      Object.keys(readingLists.value),
    );

    const defaultList = computed<ReadingList>(() => {
      const list = readingLists.value[defaultListName];
      if (!list) {
        throw new Error("The default reading list is missing.");
      }
      return list;
    });

    function listContainsItem(listName: string, commentId: number): boolean {
      const list = readingLists.value[listName];
      if (!list) {
        return false;
      }
      return list.items.some((item) => item.commentId === commentId);
    }

    /**
     * Add items to a list.
     */
    function addToList(listName: string, items: ReadingListItem[]): void {
      // Find the lists that the function was called for, so we can use it later
      const list = readingLists.value[listName];
      if (!list) {
        // The list does not exist. Do nothing.
        return;
      }

      // Add only those items that are not already in the list.
      const commentIdsAlreadyInList = list.items.map((item) => item.commentId);
      readingLists.value = {
        ...readingLists.value,
        [listName]: {
          ...list,
          items: [
            ...list.items,
            ...items.filter(
              (item) => !commentIdsAlreadyInList.includes(item.commentId),
            ),
          ],
        },
      };
    }

    function removeFromList(listName: string, commentIds: number[]): void {
      // Find the lists that the function was called for, so we can use it later
      const list = readingLists.value[listName];
      if (!list) {
        // The list does not exist. Do nothing.
        return;
      }

      readingLists.value = {
        ...readingLists.value,
        [listName]: {
          ...list,
          items: list.items.filter(
            (item) => !commentIds.includes(item.commentId),
          ),
        },
      };
    }

    /**
     * Add a new list, and return its name.
     */
    function newList(title: string): string {
      const name = getListNameFromTitle(title);

      if (!readingLists.value[name]) {
        readingLists.value = {
          ...readingLists.value,
          [name]: newEmptyList(title, name),
        };
      }

      return name;
    }

    /**
     * Create a new list containing all items from another list.
     */
    function copyList(newListTitle: string, list: ReadingList): string {
      const newName = getListNameFromTitle(newListTitle);

      if (!readingLists.value[newName]) {
        readingLists.value = {
          ...readingLists.value,
          [newName]: {
            ...newEmptyList(newListTitle, newName),
            items: [...list.items],
          },
        };
      }

      return newName;
    }

    function removeAllItemsFromList(listName: string): void {
      const list = readingLists.value[listName];
      if (!list) {
        // The list does not exist. Do nothing.
        return;
      }

      readingLists.value = {
        ...readingLists.value,
        [listName]: {
          ...list,
          items: [],
        },
      };
    }

    function deleteList(listName: string, force: boolean): void {
      // Find the lists that the function was called for, so we can use it later

      const list = readingLists.value[listName];
      if (!list) {
        // The list does not exist. Do nothing.
        return;
      }

      // Do not delete the list if it has items in it, unless the `force` is true.
      if (list.items.length > 0 && !force) {
        return;
      }

      delete readingLists.value[listName];
      // shallow copy to force update
      readingLists.value = { ...readingLists.value };
    }

    function moveUp(listName: string, commentId: number): void {
      const list = readingLists.value[listName];
      if (!list) {
        // The list does not exist. Do nothing.
        return;
      }

      const itemIndex = list.items.findIndex((i) => i.commentId === commentId);
      const item = list.items.find((i) => i.commentId === commentId);

      if (itemIndex <= 0 || !item) {
        return;
      }

      list.items.splice(itemIndex, 1);
      list.items.splice(itemIndex - 1, 0, item);
      // shallow copy to force update
      readingLists.value = { ...readingLists.value };
    }

    function moveDown(listName: string, commentId: number): void {
      const list = readingLists.value[listName];
      if (!list) {
        // The list does not exist. Do nothing.
        return;
      }

      const itemIndex = list.items.findIndex((i) => i.commentId === commentId);
      const item = list.items.find((i) => i.commentId === commentId);

      if (itemIndex === list.items.length - 1 || itemIndex < 0 || !item) {
        return;
      }

      list.items.splice(itemIndex, 1);
      list.items.splice(itemIndex + 1, 0, item);
      // shallow copy to force update
      readingLists.value = { ...readingLists.value };
    }

    return {
      readingLists,
      readingListsArray: computed<ReadingList[]>(() => {
        return Object.values(readingLists.value).filter(isDefined);
      }),
      readingListsNames,
      defaultList,
      listContainsItem,
      addToList,
      removeFromList,
      newList,
      copyList,
      removeAllItemsFromList,
      deleteList,
      moveUp,
      moveDown,
      $reset,
    };
  },
  {
    persist: true,
    share: {
      enable: true,
      initialize: false,
    },
  },
);

export type ReadingListsStore = ReturnType<typeof useReadingListsStore>;

export interface ReadingList {
  title: string;
  name: string;
  createdDate: number;
  items: ReadingListItem[];
}

export interface ReadingListItem {
  commentId: number;
  addedDate: number;
}

/**
 * Given a list title, get an appropriate key to identify the list in the storage.
 */
export function getListNameFromTitle(title: string): string {
  // Replace all non-word characters in the lowercase of the title with "-".
  return title.toLowerCase().replace(/\W/g, "-");
}

export function isDefaultList(name: string): boolean {
  return name === defaultListName;
}

function getInit(): { readingLists: Dict<ReadingList> } {
  return {
    readingLists: {
      [defaultListName]: newEmptyList(defaultListTitleEn, defaultListName),
    },
  };
}

function newEmptyList(title: string, name: string): ReadingList {
  return {
    title,
    name,
    createdDate: Date.now(),
    items: [],
  };
}

export const defaultListName = "_starred";
export const defaultListTitleKey = "ReadingList.StarredListTitle";
export const defaultListTitleEn = "Starred";
