/**
 * The Fetch API, but with some extensions:
 * - It's wrapped in a very thin cache layer to prevent identical simultaneous requests.
 * - It keeps track of pending requests so that the UI can show some loading animation.
 *
 * We rely on network/browser/edge caching as much as possible, to keep the code base as simple as possible. However,
 * when we visit a page for the first time, or when doing a hard refresh, multiple identical requests are made
 * simultaneously, and they all run to the network, because there is no cache. This makes the app a lot slower. The
 * solution is to prevent identical simultaneous requests by keeping track of requests that are still in flight.
 *
 * DEVELOPER NOTE: Be very careful with `await` here. The `await` statement yields control, and can easily cause a
 * race condition when trying to deduplicate network requests.
 *
 * Inspiration:
 * - https://softwareengineering.stackexchange.com/a/416337/277341
 */
export class FetchApiWrapper {
  /**
   * The fetch function to wrap.
   */
  private readonly originalFetch: typeof window.fetch;

  /**
   * A function that creates a `Request` object from `fetch` arguments. In browsers, the default `Request` constructor
   * should do the trick here, e.g.: `(input, init) => new Request(input, init)`. This is also an opportunity to
   * modify the request, e.g., to add `keepalive: true`.
   */
  private readonly createRequest: (
    input: RequestInfo | URL,
    init?: RequestInit,
  ) => Request;

  /**
   * Response promises for pending requests.
   */
  private readonly cachedResponses: Map<string, Promise<Response>>;

  /**
   * An object on which we can emit events. Other parts of the app can listen to these events.
   */
  public readonly events: EventTarget;

  constructor(
    fetch: typeof window.fetch,
    createRequest: (input: RequestInfo | URL, init?: RequestInit) => Request,
  ) {
    this.originalFetch = fetch;
    this.cachedResponses = new Map();
    this.events = new EventTarget();
    this.createRequest = createRequest;
  }

  /**
   * See https://developer.mozilla.org/en-US/docs/Web/API/fetch
   */
  fetch: typeof window.fetch = (input, init) => {
    const req = this.createRequest(input, init);
    if (FetchApiWrapper.shouldDeduplicate(req)) {
      return this.deduplicate(req).then((r) => r.clone());
    }
    return this.trackRequestCount(req);
  };

  /**
   * Check whether this is the kind of request we want to deduplicate.
   */
  private static shouldDeduplicate(req: Request): boolean {
    return req.method === "GET" && req.cache === "default";
  }

  /**
   * Cache the pending requests until they are done, so that we can deduplicate them.
   */
  private deduplicate(req: Request): Promise<Response> {
    const id = FetchApiWrapper.getRequestId(req);

    const cachedResponsePromise = this.cachedResponses.get(id);
    if (cachedResponsePromise) {
      return cachedResponsePromise;
    }

    // This request is not currently in flight. Start it.
    this.emitRequestStarted();
    const responsePromise = this.originalFetch(req).finally(() => {
      // This is request is done, i.e., no longer in flight.
      this.emitRequestEnded();
      this.cachedResponses.delete(id);
    });

    // Store the promise for later.
    this.cachedResponses.set(id, responsePromise);

    return responsePromise;
  }

  /**
   * Keep track of the number of pending requests.
   */
  private trackRequestCount(req: Request) {
    this.emitRequestStarted();
    return this.originalFetch(req).finally(() => {
      this.emitRequestEnded();
    });
  }

  /**
   * Get a string that
   * - identifies this request
   * - is the same for all requests which are considered identical.
   * - is unique among any requests which are not considered identical.
   */
  private static getRequestId(req: Request): string {
    return `${req.method}:${req.url}`;
  }

  private emitRequestStarted() {
    this.events.dispatchEvent(new Event("request_started"));
  }

  private emitRequestEnded() {
    this.events.dispatchEvent(new Event("request_ended"));
  }
}
