import { Capacitor } from "@capacitor/core";
import type { Pinia } from "pinia";
import { type GetResult, Preferences } from "@capacitor/preferences";

export default defineNuxtPlugin((nuxtApp) => {
  if (!Capacitor.isNativePlatform()) return;

  (nuxtApp.$pinia as Pinia).use((context) => {
    if (!context.options.persist) return;

    if (!context.store.$state._cap) {
      Preferences.get({
        key: context.store.$id,
      }).then(({ value }: GetResult) => {
        context.store.$state = {
          ...JSON.parse(value ?? "{}"),
          // _cap does not exist in any store state,
          // this is purely for the plugin to keep track of whether it loaded the state or not
          _cap: value ? "loaded" : "no local state",
        };
      });

      // Subscribe to the store and keep the preferences storage up to date.
      context.store.$subscribe(
        (_, state) => {
          // Do not save _cap to the local storage.
          const { _cap, ...toSave } = state;
          Preferences.set({
            key: context.store.$id,
            value: JSON.stringify(toSave),
          }).then();
        },
        { deep: true, immediate: true },
      );
    }
  });
});
