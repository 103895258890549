import { filterInPlace } from "~/units/arrays";

/**
 * Enqueue a value to `arr`, treating it as a queue of finite length.
 * If the value is already in the array, it is moved to the top.
 *
 * @param arr
 *   The array that holds the recent list. It will be modified in place.
 * @param value
 *   The value to add to the queue.
 * @param maxLength
 *   The maximum length of the state[name] array before throwing away the oldest values.
 * @param filterCallback
 *   A callback function that decides whether two values are equivalent.
 *   The first value is the existing value, and the second is the new value, which the caller passed in.
 */
export function addRecent<T>(
  arr: T[],
  value: T,
  maxLength: number,
  filterCallback?: (a: T, b: T) => boolean,
): void {
  if (filterCallback) {
    // If the value is already in the array (according to the definition of equivalence provided by the
    // filterCallback function), remove it.
    filterInPlace(arr, (item) => {
      return filterCallback(item, value);
    });
  }

  // Add the new value to the start of the array.
  arr.unshift(value);

  // Don't allow the array to exceed the maximum length.
  while (arr.length > maxLength) {
    arr.pop();
  }
}

/**
 * Trim each value and remove empty values from the array of translation IDs.
 */
export function cleanStringArray(tids: string[]): string[] {
  return tids.map((tid) => tid.trim()).filter((tid) => tid.length);
}

/**
 * Convert a comma-separated string of translation IDs to an array of translation IDs.
 */
export function commaSeparatedStringToArray(str: string): string[] {
  return cleanStringArray(str.split(","));
}
