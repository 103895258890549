import { defineStore } from "pinia";
import {
  createVidRange,
  parseVidsString,
  type VidRange,
  type VidRangeData,
} from "@rsc/scripture-util";
import { computed, ref, watch } from "vue";
import type { RouteLocationNormalized } from "vue-router";
import { addRecent } from "./util";
import { type BibleStore, useBibleStore } from "~/stores/useBibleStore";
import {
  type CommentaryFiltersStore,
  useCommentaryFiltersStore,
} from "~/stores/useCommentaryFiltersStore";

/**
 * Create a pinia store for keeping recent values for the user interface.
 */
export function createUserRecentStore(
  useBibleStore: () => BibleStore,
  useCommentaryFiltersStore: () => CommentaryFiltersStore,
) {
  return defineStore(
    "userRecent",
    () => {
      const getInit = () => ({ scriptureData: [], navigationHistory: [] });
      const init = getInit();

      /**
       * Recently visited VID ranges data.
       */
      const scriptureData = ref<VidRangeData[]>(init.scriptureData);

      /**
       * Recently performed navigations inside our app.
       *
       * This is used, e.g., to check what `router.back()` will do before calling it.
       */
      const navigationHistory = ref<Navigation[]>(init.navigationHistory);

      function $reset() {
        const init = getInit();
        scriptureData.value = init.scriptureData;
        navigationHistory.value = init.navigationHistory;
      }

      /**
       * Recently visited VID ranges objects.
       */
      const scripture = computed<VidRange[]>(() =>
        scriptureData.value.map((item) => createVidRange(item)),
      );

      /**
       * Add a VidRange to the scripture history list.
       */
      function addScripture(range: VidRange): void {
        // Don't store the translation, only the VID ranges.
        // The user probably does not want to change the translation when selecting a range from the history.
        // This also helps prevent duplicate suggestions.
        range = range.changeTranslation(null);

        addRecent(
          scriptureData.value,
          range.dump(),
          50,
          (item) => !range.sameAs(item),
        );
      }

      /**
       * Helper functions for the watchers below.
       * Add a VID range to the scripture history list if it has changed.
       */
      const addScriptureIfVidRangeChanged = (
        newRange: VidRange | null,
        oldRange: VidRange | null,
      ) => {
        if (newRange && !newRange.sameAs(oldRange)) {
          addScripture(newRange);
        }
      };

      // If the commentary filters change, add the new VID range to the history.
      const commentaryFiltersStore = useCommentaryFiltersStore();
      watch(
        () => {
          const { vids } = commentaryFiltersStore;
          return vids ? (parseVidsString(vids, null)[0] ?? null) : null;
        },
        addScriptureIfVidRangeChanged,
        { immediate: false, deep: true },
      );

      // If the Bible state changes, add the new VID range to the history.
      const bibleStore = useBibleStore();
      watch(
        () => {
          return parseVidsString(bibleStore.vids ?? "", null)[0] ?? null;
        },
        addScriptureIfVidRangeChanged,
        { immediate: false, deep: true },
      );

      function addNavigation(to: LocationInfo, from: LocationInfo): void {
        addRecent(
          navigationHistory.value,
          { to: pickLocationInfo(to), from: pickLocationInfo(from) },
          3,
        );
      }

      return {
        scriptureData,
        scripture,
        addScripture,
        navigationHistory,
        addNavigation,
        $reset,
      };
    },
    {
      persist: {
        paths: [
          "scriptureData",
          // Do NOT persist the navigation history. It MUST be empty when the user opens the app.
        ],
      },
    },
  );
}

export const useUserRecentStore = createUserRecentStore(
  useBibleStore,
  useCommentaryFiltersStore,
);

export type UserRecentStore = ReturnType<typeof useUserRecentStore>;

type LocationInfo = Pick<
  RouteLocationNormalized,
  "name" | "query" | "params" | "hash" | "path" | "fullPath"
>;

interface Navigation {
  to: LocationInfo;
  from: LocationInfo;
}

/**
 * Pick only the properties we need from the location object. This ensures that we don't store any extra data.
 * The full RouteLocationNormalized object contains unserializable objects like functions, which we cannot store.
 */
function pickLocationInfo(location: LocationInfo): LocationInfo {
  const { name, query, params, hash, path, fullPath } = location;
  return { name, query, params, hash, path, fullPath };
}
