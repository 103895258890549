import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

export default defineNuxtPlugin((_) => {
  if (!Capacitor.isNativePlatform()) return;

  App.addListener("backButton", ({ canGoBack }) => {
    if (canGoBack) {
      window.history.back();
    } else {
      App.exitApp().then();
    }
  }).then();
});
