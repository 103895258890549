import { inject, type InjectionKey } from "vue";

/**
 * Type-safe dependency injection.
 * Inspired by https://logaretm.com/blog/type-safe-provide-inject/
 */
export function injectRequired<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Failed to inject '${key.description}'.`);
  }
  return resolved;
}
