import validate from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import memorize_45global from "/opt/atlassian/pipelines/agent/build/app/middleware/memorize.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  memorize_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  bible: () => import("/opt/atlassian/pipelines/agent/build/app/middleware/bible.ts"),
  commentary: () => import("/opt/atlassian/pipelines/agent/build/app/middleware/commentary.ts")
}