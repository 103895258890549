
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "build": {
    "user": "root",
    "host": "55713b271616",
    "time": "2024/11/07 19:14:04",
    "commit": "9a8618ee417c128fd9e183c16a0114d86af42e24",
    "repo": "git@bitbucket.org:rscdevelopers/vv-public.git",
    "branch": "master",
    "release": "9a8618ee417c128fd9e183c16a0114d86af42e24"
  },
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
