import { defineStore } from "pinia";
import { computed, ref } from "vue";
import {
  createVidRange,
  type VidRange,
  type VidRangeData,
} from "@rsc/scripture-util";

/**
 * A pinia store for the verse preview dialog that pops up when clicking on verse references.
 *
 * The dialog needs to be global because it's invalid HTML to have `div.v-dialog__container` inside `span` or `p`.
 * It's also good to have one dialog instead of many, to reduce the total size of the markup.
 */
export const useVersePreviewStore = defineStore(
  "versePreview",
  () => {
    const getInit = () => ({
      rangeData: null,
      dialogOpen: false,
    });
    const init = getInit();

    /**
     * The VID range data.
     */
    const rangeData = ref<VidRangeData | null>(init.rangeData);

    /**
     * The VID range object.
     */
    const range = computed<VidRange | null>(() => {
      const data = rangeData.value;
      return data ? createVidRange(data) : null;
    });

    /**
     * The dialog's open/close model.
     */
    const dialogOpen = ref<boolean>(init.dialogOpen);

    function preview(range: VidRange) {
      rangeData.value = range.dump();
      dialogOpen.value = true;
    }

    function close() {
      dialogOpen.value = false;
      rangeData.value = null;
    }

    function $reset() {
      const init = getInit();
      rangeData.value = init.rangeData;
      dialogOpen.value = init.dialogOpen;
    }

    return { rangeData, range, dialogOpen, preview, close, $reset };
  },
  {
    persist: false,
  },
);

export type VersePreviewStore = ReturnType<typeof useVersePreviewStore>;
