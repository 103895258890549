import type { SeverityLevel } from "@sentry/types";

export type RfcLogLevelNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type RfcLogLevelName =
  | "emergency"
  | "alert"
  | "critical"
  | "error"
  | "warning"
  | "notice"
  | "info"
  | "debug";

/**
 * RFC Log Levels.
 */
export const logLevels: Record<RfcLogLevelName, RfcLogLevelNumber> = {
  // Emergency: The system is unusable.
  emergency: 0,
  // Alert: Action must be taken immediately.
  alert: 1,
  // Critical conditions.
  critical: 2,
  // Error conditions.
  error: 3,
  // Warning conditions.
  warning: 4,
  // Normal but significant conditions.
  notice: 5,
  // Informational messages.
  info: 6,
  // Debug-level messages.
  debug: 7,
};

/**
 * Mapping of RFC log levels to Sentry's "severity" levels.
 */
export const severities: Record<RfcLogLevelNumber, SeverityLevel> = {
  0: "fatal",
  1: "error",
  2: "error",
  3: "error",
  4: "warning",
  5: "log",
  6: "info",
  7: "debug",
};
