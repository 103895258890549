<template lang="pug">
VDialog.the-verse-preview-dialog(
  v-model="versePreviewStore.dialogOpen"
  :attach="dialogAttach"
  width="500"
  :scrollable="true"
  @click:outside="versePreviewStore.close"
)
  VCard.pt-3
    VCardTitle.pl-6.text-h3(v-html="renderedReference.value || '…'")
    VCardText(v-if="verses.value?.length")
      BibleVerse.pl-4(
        v-for="v in verses.value"
        :key="v.fromVid"
        :verse="v"
        :show-translation="true"
        :dense="true"
        :show-heading="true"
        :ref-number-outer="true"
        :show-study-button="true"
      )
    VCardText(v-else-if="verses.isPending")
      VSkeletonLoader(type="text")
    VDivider
    VCardActions.pa-3
      VBtn.the-verse-preview-dialog__bible-button(
        v-if="bibleLocation"
        :to="localePath(bibleLocation)"
        :title="i18n.t('BibleRef.OpenScriptureInBibleView')"
        color="primary-c700"
        variant="elevated"
        elevation="3"
        @click="versePreviewStore.close"
      )
        VIcon.mr-2 {{ mdiEye }}
        | {{ i18n.t("BibleRef.ReadInBible") }}
      VSpacer
      VBtn.the-verse-preview-dialog__close-button(
        variant="outlined"
        @click="versePreviewStore.close"
      ) {{ i18n.t("Global.close") }}
</template>

<script setup lang="ts">
import { createVerse } from "@rsc/scripture-util";
import { computed } from "vue";
import { mdiEye } from "@mdi/js";
import { okAsync } from "neverthrow";
import { useI18n } from "vue-i18n";
import { useVersePreviewStore } from "~/stores/useVersePreviewStore";
import { injectRequired } from "~/injectRequired";
import { useComputedResultAsync } from "~/composables/useComputedResultAsync";
import {
  getBibleLocationFromRange,
  type SimpleNamedLocation,
} from "~/routing/locations";
import { ScriptureClientKey } from "~/injectionKeys";

const i18n = useI18n();
const localePath = useLocalePath();

const scriptureClient = injectRequired(ScriptureClientKey);
const versePreviewStore = useVersePreviewStore();

interface Props {
  /**
   * Where to attach the dialog.
   */
  dialogAttach?: HTMLElement;

  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;
}

const props = defineProps<Props>();

const bibleLocation = computed<SimpleNamedLocation | undefined>(() => {
  const r = versePreviewStore.range;
  if (!r) {
    return;
  }
  return getBibleLocationFromRange(r);
});

/**
 * The vidRange rendered as a human-readable range.
 */
const renderedReference = useComputedResultAsync(
  () =>
    versePreviewStore.range?.renderReference(
      scriptureClient,
      props.localTid,
      true,
      false,
    ) || okAsync(""),
);

const verses = useComputedResultAsync(() => {
  const rng = versePreviewStore.range;
  if (!rng) {
    return okAsync([]);
  }

  return rng
    .changeTranslation(rng.tid || props.localTid)
    .limitAfter(scriptureClient, 50)
    .andThen((rngLimited) => rngLimited.getAllVerses(scriptureClient))
    .map((versesData) => versesData.map((verseData) => createVerse(verseData)));
});
</script>
