<template lang="pug">
VContainer
  VRow(v-if="title")
    VCol
      HH.text-h1 {{ title }}

  VRow
    VCol
      ErrorAlert(
        :reporting="reporting"
        :message="message"
        :stack="stack"
        :stack-is-html="stackIsHtml"
        :status-code="statusCode"
        @retry="retry"
      )
        slot

  // Calls to action.
  VRow
    VCol
      VCard
        VCardText {{ i18n.t("Error.ScriptureIndexCTA") }}
        VCardActions
          VBtn.text-black.ml-auto(
            variant="outlined"
            :to="localePath(getScriptureIndexBooksLocation())"
          ) {{ i18n.t("ScriptureIndex.ScriptureIndex") }}
    VCol
      VCard
        VCardText {{ i18n.t("Error.GoHomeCTA") }}
        VCardActions
          VBtn.text-black.ml-auto(
            variant="outlined"
            :to="localePath('/')"
          ) {{ i18n.t("Error.GoHomeCTAButton") }}
</template>

<script setup lang="ts">
/**
 * Use this for a full-page error.
 */
import { useI18n } from "vue-i18n";
import { getScriptureIndexBooksLocation } from "~/routing/locations";
import { hasListener } from "~/composables/useProps";
import type { ErrorReportingInfo } from "~/components/errorAlert.util";
import HH from "~/headings/components/HH.vue";
import ErrorAlert from "~/components/ErrorAlert.vue";

const i18n = useI18n();
const localePath = useLocalePath();

interface Props {
  /**
   * The title of the error page.
   */
  title?: string;

  /**
   * The error message, as a string.
   * This is useful when we don't have a full `Error` object.
   */
  message?: string;

  /**
   * The error stack, as a string.
   * This is useful when we don't have a full `Error` object.
   */
  stack?: string;

  /**
   * Whether the stack is HTML. If `false`, the stack is treated as plain text and placed in a `<pre>` tag.
   */
  stackIsHtml?: boolean;

  /**
   * Sentry error reporting.
   * - If this is `false`, the report button is hidden.
   * - If this is an object, the report button is shown.
   */
  reporting?: false | ErrorReportingInfo;

  /**
   * The HTTP status code to use if this component exists in SSR or pre-rendering.
   * The HTTP response for a page containing this component will have this status code.
   * A page with a status code >= 400
   * - should not be cached in CDNs or in browsers.
   * - should not be included in the pre-rendered build.
   */
  statusCode?: number;
}

const props = withDefaults(defineProps<Props>(), {
  title: "Error",
  reporting: false,
  stackIsHtml: false,
});

interface Emits {
  (e: "retry"): void;
}

const emit = defineEmits<Emits>();

useRobotsRule(false);

useHead({
  title: props.title,
});

const retry = hasListener("retry") ? () => emit("retry") : undefined;
</script>

<style scoped lang="scss">
.the-error__stack {
  max-height: 50vh;
}
</style>
