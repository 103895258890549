import type { Router } from "vue-router";
import { bindBibleAndRoute } from "~/routing/bibleAndRoute";
import { useBibleStore } from "~/stores/useBibleStore";
import { bindCommentaryFiltersAndRoute } from "~/routing/commentaryFiltersAndRoute";
import { useCommentaryFiltersStore } from "~/stores/useCommentaryFiltersStore";
import { createFunctionUpdateQueryAndParams } from "~/routing/routes";

export default defineNuxtPlugin(async (nuxtApp) => {
  const router = nuxtApp.$router as Router;
  const bibleStore = useBibleStore();
  const commentaryFiltersStore = useCommentaryFiltersStore();

  // Get a reactive version of `router.currentRoute`.
  // See https://codesandbox.io/s/reactive-routercurrentroute-in-vue-2-composition-api-r6obx?file=/src/components/CurrentLocation.vue
  const currentRoute = toRef(reactive(router), "currentRoute");

  const getRoute = () => currentRoute.value;
  const updateQueryAndParams = createFunctionUpdateQueryAndParams(router);

  bindCommentaryFiltersAndRoute(
    commentaryFiltersStore,
    getRoute,
    updateQueryAndParams,
  );
  bindBibleAndRoute(bibleStore, getRoute, updateQueryAndParams);
});
