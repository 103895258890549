/**
 * A pinia store for the PWA state.
 */
/// <reference types="vite-plugin-pwa/client" />
import { registerSW } from "virtual:pwa-register";
import { defineStore } from "pinia";
import { ref } from "vue";
import { usePreferencesStore } from "./usePreferencesStore";

export const usePwaStore = defineStore("pwa", () => {
  const getInit = () => ({
    updateAvailable: false,
    registration: null,
    deferredInstallPrompt: null,
    offlineReady: false,
    error: null,
    updateServiceWorker: () => Promise.resolve(),
  });
  const init = getInit();

  const preferences = usePreferencesStore();

  /**
   * Whether a service worker update is available.
   */
  const updateAvailable = ref<boolean>(init.updateAvailable);

  /**
   * Update the app by reloading app pages that use our service worker.
   */
  function update() {
    const r = registration.value;
    if (!r || !r.waiting) {
      return;
    }

    // The generated service-worker.js should respond to this message by calling skipWaiting on itself.
    r.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  /**
   * Whether the app is ready for offline use.
   */
  const offlineReady = ref<boolean>(init.offlineReady);

  /**
   * The service worker registration object.
   */
  const registration = ref<ServiceWorkerRegistration | null>(init.registration);

  /**
   * The error that occurred when registering the service worker, e.g., when the user rejected installing the app.
   */
  const error = ref<any>(init.error);

  /**
   * The installation prompt event.
   */
  const deferredInstallPrompt = ref<BeforeInstallPromptEvent | null>(
    init.deferredInstallPrompt,
  );

  const updateServiceWorker = ref<() => Promise<void>>(
    init.updateServiceWorker,
  );

  /**
   * The user explicitly asked to install the app.
   */
  async function installYes() {
    const prompt = deferredInstallPrompt.value;
    if (!prompt) {
      return;
    }

    // Show the browser prompt to install the PWA.
    await prompt.prompt();
    const choiceResult = await prompt.userChoice;

    // If the user accepted installation, then we may ask again to install after they have uninstalled.
    // If the user denied installation, then we will never ask again.
    preferences.doNotInstall = choiceResult.outcome !== "accepted";

    // Clear the prompt.
    deferredInstallPrompt.value = null;
  }

  /**
   * The user explicitly denied to install the app.
   */
  async function installNo() {
    // Do not ask again.
    preferences.doNotInstall = true;

    // Clear the prompt.
    deferredInstallPrompt.value = null;
  }

  function $reset() {
    const init = getInit();
    updateAvailable.value = init.updateAvailable;
    registration.value = init.registration;
    deferredInstallPrompt.value = init.deferredInstallPrompt;
  }

  /**
   * Try to register the service worker.
   *
   * This function must be called at startup.
   * This tells the browser that this is a progressive web app.
   *
   * Useful docs:
   * - Feature detection examples: https://github.com/tomayac/pwa-feature-detector/blob/master/src/main.js
   * - Reloading properly: https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
   * - Vite PWA plugin: https://vite-pwa-org.netlify.app/guide/
   */
  function register(): void {
    if (import.meta.env.DEV) {
      // Only register service worker in production mode.
      return;
    }

    if (!window || !navigator || !("serviceWorker" in navigator)) {
      // Service workers are disabled or not supported (e.g., in SSR environment).
      return;
    }

    updateServiceWorker.value = registerSW({
      onNeedRefresh(): void {
        // New content is available; prompt the user to refresh.
        updateAvailable.value = true;
      },

      onOfflineReady(): void {
        // Tell the user that the app is ready to be used offline.
        offlineReady.value = true;
      },

      onRegisteredSW(
        swScriptUrl: string,
        r: ServiceWorkerRegistration | undefined,
      ): void {
        if (!r) {
          return;
        }

        registration.value = r;

        // Check for updates periodically.
        setInterval(
          () =>
            r.update().catch(() => {
              // Failed to check for update. Just ignore it.
            }),
          60 * 60 * 1000,
        );
      },

      onRegisterError(e: any): void {
        // This usually happens when the user rejects installing the app.
        error.value = e;
      },
    });

    // When a new version is installed, reload all open tabs that use our service worker.
    // This happens when the service worker calls `skipWaiting` on itself,
    // which happens when the "SKIP_WAITING" message is posted to the waiting service worker.
    let isRefreshing = false;
    navigator.serviceWorker.addEventListener("controllerchange", async () => {
      // The controller has changed.
      if (isRefreshing) {
        // Already refreshing.
        // Guard against infinite refresh loops.
        return;
      }

      // Refreshing now.
      isRefreshing = true;
      window.location.reload();
    });

    window.addEventListener("beforeinstallprompt", (e) => {
      if (isBeforeInstallPromptEvent(e)) {
        // The user may be prompted to install the app.
        deferredInstallPrompt.value = e;
      }
    });
  }

  return {
    register,
    updateAvailable,
    update,
    offlineReady,
    registration,
    error,
    deferredInstallPrompt,
    installYes,
    installNo,
    $reset,
  };
});

export type PwaStore = ReturnType<typeof usePwaStore>;

/**
 *
 * The BeforeInstallPromptEvent is fired before a user is prompted to "install" a website to a home screen on mobile.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
 * @see https://stackoverflow.com/a/51847335
 * @see https://github.com/Bartozzz/vue-pwa-install/blob/084e39dc6c437dc9a21fd83161d0d0be702f8f9d/src/index.ts#L11
 */
export interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as, for example,
   * "web" or "play" which would allow the user to choose between a web version or an Android version.
   */
  readonly platforms: string[];

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Show the browser's install prompt.
   */
  prompt(): Promise<void>;
}

function isBeforeInstallPromptEvent(e: Event): e is BeforeInstallPromptEvent {
  return Boolean(e) && "prompt" in e;
}
