/**
 * Functions for constructing Location objects for various pages.
 */

import type { CommentaryFilters } from "~/stores/useCommentaryFiltersStore";
import type { BibleStoreRouteState } from "~/stores/useBibleStore";

export interface SimpleNamedLocation {
  name: string;
  params: Record<string, string>;
  query: Record<string, string>;
}

export function isSimpleNamedLocation(
  location: any,
): location is SimpleNamedLocation {
  return (
    typeof location === "object" &&
    location &&
    typeof location.name === "string" &&
    typeof location.params === "object" &&
    location.params &&
    typeof location.query === "object" &&
    location.query
  );
}

export function expectSimpleNamedLocation(location: any): SimpleNamedLocation {
  if (!isSimpleNamedLocation(location)) {
    throw new Error(`Expected a simple named location.`);
  }
  return location;
}

export function getCommentaryLocationFromFilters(
  filters: CommentaryFilters,
): SimpleNamedLocation {
  return {
    name: "commentary-vids-type",
    params: {
      type: filters.commentaryTypeShortName,
      vids: filters.vids,
    },
    query: {
      detail: filters.detailLevelWeight.toString(),
      languages: filters.languages.join(","),
    },
  };
}

export function getCommentaryLocationFromRange(
  typeShortName: string,
  range: {
    fromVid: number;
    toVid: number;
  },
  detailLevelWeight?: number,
  languages?: string[],
): SimpleNamedLocation {
  const query: Record<string, string> = {};
  if (detailLevelWeight !== undefined) {
    query.detail = detailLevelWeight.toString();
  }
  if (languages !== undefined) {
    query.languages = languages.join(",");
  }

  return {
    name: "commentary-vids-type",
    params: {
      type: typeShortName,
      vids:
        range.fromVid === range.toVid
          ? `${range.fromVid}`
          : `${range.fromVid}-${range.toVid}`,
    },
    query,
  };
}

export function getBibleLocationFromRange(range: {
  fromVid: number;
  toVid: number;
  tid?: string | null;
}): SimpleNamedLocation {
  const query: Record<string, string> = {};
  if (range.tid) {
    query.tid = range.tid;
  }

  return {
    name: "bible-vids",
    params: {
      vids:
        range.fromVid === range.toVid
          ? `${range.fromVid}`
          : `${range.fromVid}-${range.toVid}`,
    },
    query,
  };
}

export function getBibleLocationFromState(
  state: BibleStoreRouteState,
): SimpleNamedLocation {
  return {
    name: "bible-vids",
    query: {
      tids: state.tids,
    },
    params: {
      vids: state.vids,
    },
  };
}

export function getBibleSearchLocation(): SimpleNamedLocation {
  return {
    name: "bible-search",
    params: {},
    query: {},
  };
}

export function getCommentLocation(nid: number): SimpleNamedLocation {
  return {
    name: "comment-nid",
    params: { nid: nid.toString() },
    query: {},
  };
}

export function getScriptureIndexBooksLocation(): SimpleNamedLocation {
  return {
    name: "scripture-index",
    params: {},
    query: {},
  };
}

export function getScriptureIndexChaptersLocation(
  bid: string,
): SimpleNamedLocation {
  return {
    name: "scripture-index-bid",
    params: { bid },
    query: {},
  };
}

export function getNewsLocation(nid: number): SimpleNamedLocation {
  return {
    name: "news-nid",
    params: { nid: nid.toString() },
    query: {},
  };
}

export function getListsLocation(): SimpleNamedLocation {
  return {
    name: "lists",
    params: {},
    query: {},
  };
}

export function getListViewLocation(name: string): SimpleNamedLocation {
  return {
    name: "list-name-view",
    params: { name },
    query: {},
  };
}

export function getListReadLocation(name: string): SimpleNamedLocation {
  return {
    name: "list-name-read",
    params: { name },
    query: {},
  };
}

export function getHomeLocation(): SimpleNamedLocation {
  return {
    name: "index",
    params: {},
    query: {},
  };
}
