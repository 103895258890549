import type { RouteLocation, RouteLocationNormalized } from "vue-router";
import { computed, ref, watch } from "vue";
import type { RouteMiddleware } from "#app/composables/router";
import type { UpdateQueryAndParamsFunction } from "~/routing/routes";
import { dictsDiffer } from "~/units/objectsCompare";
import type { SimpleNamedLocation } from "~/routing/locations";

/**
 * Set up a two-way binding between a route and a state.
 *
 * @param getState
 *   A getter that returns the current state. It may be undefined if the state is not yet ready. In this case, the
 *   route will not be updated from the state, but the state may be updated from the route, thereby making it ready.
 * @param setState
 *   A setter that modifies the state.
 * @param hasStateChanged
 *   A function that compares two states to see if they really changed. This is used to ignore unnecessary updates.
 * @param getRoute
 *   A getter that returns the current route of the app.
 * @param updateQueryAndParams
 *   A function that will update the `query` and/or `params` in the route.
 *   It is the responsibility of this function to skip unnecessary navigations.
 * @param routeNameRegex
 *   A regular expression for the name of the route to bind.
 *   The binding is only active when the current route name matches this pattern.
 * @param getDesiredLocationFromState
 *   Given a state, get the location on which the router should be.
 * @param getDesiredStateFromLocation
 *   Given a router location and previous state, get the new state.
 *   The previous state may be undefined.
 */
export function useStateRouteBinding<T>(
  getState: () => T | undefined,
  setState: (value: T) => void,
  hasStateChanged: (from: T, to: T) => boolean,
  getRoute: () => Pick<RouteLocationNormalized, "name" | "query" | "params">,
  updateQueryAndParams: UpdateQueryAndParamsFunction,
  routeNameRegex: RegExp,
  getDesiredLocationFromState: (state: T) => LocationQueryAndParams,
  getDesiredStateFromLocation: (
    location: LocationQueryAndParams,
    previousState: T | undefined,
  ) => T,
): void {
  const route =
    computed<Pick<RouteLocationNormalized, "name" | "query" | "params">>(
      getRoute,
    );

  // Track whether an update is in progress.
  const updatingRouteFromState = ref<boolean>(false);
  const updatingStateFromRoute = ref<boolean>(false);
  const isBusy = computed(
    () => updatingStateFromRoute.value || updatingRouteFromState.value,
  );

  // Whether the current route is the relevant route.
  const isCorrectRoute = computed(() =>
    routeNameRegex.test(String(route.value.name ?? "")),
  );

  // A guard to pause watchers while an update is in progress, or we're on an irrelevant route.
  const guard = computed(() => isBusy.value || !isCorrectRoute.value);

  // When the route changes, update the state.
  watch(
    route,
    function () {
      if (guard.value) {
        return;
      }

      updatingStateFromRoute.value = true;
      try {
        const oldState = getState();
        const newState = getDesiredStateFromLocation(route.value, oldState);
        // Only update if the change in route will affect a real change in the state.
        if (oldState === undefined || hasStateChanged(oldState, newState)) {
          setState(newState);
        }
      } finally {
        updatingStateFromRoute.value = false;
      }
    },
    { immediate: false },
  );

  // When the state changes, update the route.
  watch(
    getState,
    async function (state) {
      if (guard.value) {
        return;
      }

      if (state === undefined) {
        // The state is not ready, so don't update the route from the state.
        return;
      }

      updatingRouteFromState.value = true;
      try {
        // Trust the `updateQueryAndParams` function to skip unnecessary navigations.
        await updateQueryAndParams(getDesiredLocationFromState(state));
      } finally {
        updatingRouteFromState.value = false;
      }
    },
    { immediate: false, deep: true },
  );
}

/**
 * Create Nuxt route middleware to use on the same route as `useStateRouteBinding`.
 */
export function createRouteMiddleware<T>(
  getState: () => T,
  setState: (value: T) => void,
  getDesiredLocationFromState: (state: T) => SimpleNamedLocation,
  getDesiredStateFromLocation: (
    location: RouteLocationNormalized,
    previousState: T,
  ) => T,
): RouteMiddleware {
  return (to, from) => {
    const newState = getDesiredStateFromLocation(to, getState());
    setState(newState);
    const desiredLocation = getDesiredLocationFromState(newState);

    const query = { ...to.query, ...desiredLocation.query };
    const params = { ...to.params, ...desiredLocation.params };

    if (dictsDiffer(query, to.query) || dictsDiffer(params, to.params)) {
      return navigateTo(
        {
          name: desiredLocation.name,
          query,
          params,
          replace: true,
        },
        { redirectCode: 302 },
      );
    }
  };
}

export type LocationQueryAndParams = {
  query: NonNullable<RouteLocation["query"]>;
  params: NonNullable<RouteLocation["params"]>;
};
