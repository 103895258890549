import solrResults from "../data/solr/taxonomy/commentaryTypes.json";
import type { Dict } from "~/types";

export type CommentaryType = {
  /**
   * Name, keyed by language code.
   */
  name: Dict<string>;

  /**
   * Description, keyed by language code.
   */
  description: Dict<string>;

  /**
   * Weight, used for sorting commentary types in the UI.
   */
  weight: number;

  /**
   * Whether to sort groups so that comments about fewer verses precede comments about more verses.
   */
  sortGroupsNarrow: boolean;

  /**
   * Short name, used in URLs.
   */
  shortName: string;

  /**
   * Universally unique identifier.
   */
  uuid: string;
};

export const unknownCommentaryType: CommentaryType = {
  name: { en: "Unknown", fr: "Inconnu" },
  description: {
    en: "Unknown commentary type",
    fr: "Type de commentaire inconnu",
  },
  weight: 65535,
  sortGroupsNarrow: true,
  shortName: "unknown",
  uuid: "ed719aa1-f361-46d0-a874-a5a1f76e2d1d",
};

export const knownCommentaryTypes: CommentaryType[] =
  solrResults.grouped.ss_uuid.groups
    .map((group) => {
      const name: Dict<string> = {};
      const description: Dict<string> = {};

      group.doclist.docs.forEach((doc) => {
        switch (doc.ss_search_api_language) {
          case "en":
            name.en = doc.tm_X3b_en_name?.[0] ?? "";
            description.en = doc.zs_description_plain[0] ?? "";
            break;
          case "fr":
            name.fr = doc.tm_X3b_fr_name?.[0] ?? "";
            description.fr = doc.zs_description_plain[0] ?? "";
            break;
        }
      });

      const doc = group.doclist.docs[0];
      if (!doc) {
        throw new Error(`No doc for commentary type ${group.groupValue}`);
      }

      return {
        name,
        description,
        weight: doc?.its_weight,
        sortGroupsNarrow: doc?.bs_sort_groups_narrow,
        shortName: doc?.ss_short_name,
        uuid: group.groupValue,
      };
    })
    .sort((a, b) => a.weight - b.weight);

/**
 * Get a commentary type from its short name. Return the unknown type if it's not available.
 */
export function getCommentaryTypeOrFallback(shortName: string): CommentaryType {
  return findCommentaryType(shortName) || unknownCommentaryType;
}

/**
 * Get a commentary type from its short name. Throw an error if it's not available.
 */
export function requireCommentaryType(shortName: string): CommentaryType {
  const type = findCommentaryType(shortName);
  if (!type) {
    throw new Error(`Unknown commentary type: ${shortName}`);
  }
  return type;
}

function findCommentaryType(shortName: string) {
  return knownCommentaryTypes.find((t) => t.shortName === shortName);
}

export const validCommentaryTypeShortNames = knownCommentaryTypes.map(
  (type) => type.shortName,
);
