import { camelize, type VNodeProps } from "vue";

/**
 * Get ALL props of the current component's vnode, including listeners defined
 * as emits. These are excluded by Vue from `$attrs` and `useAttrs`, which is
 * annoying.
 *
 * @see https://stackoverflow.com/a/76208995/836995
 */
export function useProps(): VNodeProps & { [p: string]: any } {
  return getCurrentInstance()?.vnode.props ?? {};
}

/**
 * Check if the current component has a listener for the given `emit`.
 */
export function hasListener(emitName: string): boolean {
  const propName = camelize(`on-${emitName}`);
  const handler = useProps()[propName];
  return typeof handler === "function";
}
