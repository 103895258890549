<template lang="pug">
VList(
  :bg-color="$vuetify.theme.name === 'dark' ? 'rgba(0, 0, 0, 0)' : undefined"
)
  VListItem.pt-5.pb-5
    template(#prepend)
      VAvatar.pa-2(
        :round="true"
        color="primary-c050"
        size="80"
      )
        VvLogo(logo-id="vv-logo-navigation-drawer")
    VListItemTitle
      strong.text-h2.appTitle {{ runtimeConfig.public.site.name }}
  VDivider
  VListItem(
    :to="localePath(getBibleSearchLocation())"
    :prepend-icon="mdiBookSearchOutline"
  )
    VListItemTitle {{ i18n.t("TheNavigationDrawerContent.BibleSearch") }}

  VListItem(
    :to="localePath(getScriptureIndexBooksLocation())"
    :prepend-icon="mdiViewGridOutline"
  )
    VListItemTitle {{ i18n.t("TheNavigationDrawerContent.ScriptureIndex") }}

  VListItem(
    :to="localePath(getListsLocation())"
    :prepend-icon="mdiPlaylistStar"
  )
    VListItemTitle {{ i18n.t("TheNavigationDrawerContent.ReadingLists") }}

  ClientOnly
    VListItem(
      v-if="pwaStore.deferredInstallPrompt"
      @click="pwaStore.installYes"
    )
      template(#prepend)
        VBadge(
          :model-value="!preferencesStore.doNotInstall"
          color="secondary-c900"
          :dot="true"
        )
          VIcon {{ mdiRocketLaunchOutline }}

      template(#title) {{ i18n.t("TheNavigationDrawerContent.InstallApp", { appTitle: runtimeConfig.public.site.name }) }}

      template(#append)
        VBtn(
          v-if="!preferencesStore.doNotInstall"
          :icon="true"
          variant="text"
          size="small"
          @click.stop="preferencesStore.doNotInstall = true"
        )
          VIcon {{ mdiClose }}

    VListItem(
      v-if="pwaStore.updateAvailable"
      @click="pwaStore.update"
    )
      template(#prepend)
        VBadge(
          color="secondary-c900"
          :dot="true"
        )
          VIcon {{ mdiUpdate }}

      template(#title) {{ i18n.t("TheNavigationDrawerContent.UpdateApp", { appTitle: runtimeConfig.public.site.name }) }}

  VDivider

  VListItem
    VListItemTitle
      strong {{ i18n.t("TheNavigationDrawerContent.Settings") }}
  VListItem(:prepend-icon="mdiInvertColors")
    SelectTheme.mt-1
  VListItem(
    v-if="showLanguageSwitcher"
    :prepend-icon="mdiTranslate"
  )
    SelectLanguage.mt-1
</template>

<script setup lang="ts">
import {
  mdiBookSearchOutline,
  mdiClose,
  mdiInvertColors,
  mdiPlaylistStar,
  mdiRocketLaunchOutline,
  mdiTranslate,
  mdiUpdate,
  mdiViewGridOutline,
} from "@mdi/js";
import { useI18n } from "vue-i18n";
import {
  getBibleSearchLocation,
  getListsLocation,
  getScriptureIndexBooksLocation,
} from "~/routing/locations";
import { usePwaStore } from "~/stores/usePwaStore";
import { usePreferencesStore } from "~/stores/usePreferencesStore";

const i18n = useI18n();
const runtimeConfig = useRuntimeConfig();
const preferencesStore = usePreferencesStore();
const pwaStore = usePwaStore();
const localePath = useLocalePath();

const showLanguageSwitcher = computed(
  () => runtimeConfig.public.languageChangeActive,
);
</script>

<style lang="scss">
.appTitle {
  color: rgb(var(--v-theme-primary-c900));
  position: relative;

  .v-theme--dark & {
    color: rgb(var(--v-theme-primary-c050));
  }
}

.v-list-item__prepend > .v-badge {
  // Vuetify already does this for `.v-list-item__prepend > .v-icon`, but as soon as you wrap the icon in a badge,
  // it doesn't work anymore. So we fix it here.
  margin-inline-end: 32px;
}
</style>
