<template lang="pug">
VApp
  VitePwaManifest
  NuxtPage(
    v-if="hasToolbar"
    name="toolbar"
  )
  VMain#main
    NuxtPage
  TheBottomNav(v-model:menu-open="menuOpen")
  TheNavigationDrawer(v-model:menu-open="menuOpen")
  ClientOnly
    TheSnackBar
    TheVersePreviewDialog(:local-tid="localTid")
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import { computed } from "vue";
import { keepThemePreferencesSynced } from "~/themeSync";
import { usePreferencesStore } from "~/stores/usePreferencesStore";
import { localesByCode } from "~/i18n/locales";
import { never } from "~/never";
import { getStringQueryParameter } from "~/routing/routes";

const i18n = useI18n();
const localeConfig = computed(
  () => localesByCode.get(i18n.locale.value) ?? never("Locale not found."),
);
const localTid = computed<string>(() => localeConfig.value.defaultTid);
const menuOpen = ref<boolean>(false);

onMounted(() => {
  const theme = useTheme();
  const preferencesStore = usePreferencesStore();

  keepThemePreferencesSynced(theme, preferencesStore);
});

const route = useRoute();

/**
 * Whether a toolbar should be rendered.
 */
const hasToolbar = computed<boolean>(() =>
  route.matched.some((m) => m.components?.toolbar),
);

const runtimeConfig = useRuntimeConfig();

// See https://v8.i18n.nuxtjs.org/guide/seo and https://v8.i18n.nuxtjs.org/api/composables/#uselocalehead
// and https://github.com/intlify/routing/blob/main/packages/vue-i18n-routing/api.md#uselocalehead
const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
  identifierAttribute: "id",
});

/**
 * Whether this page is a mixed-language page.
 * E.g., English app showing a French comment.
 */
const isMixedLanguagePage = computed<boolean>(() => {
  const languageQueryParameter = getStringQueryParameter(route, "language");
  return (
    !!languageQueryParameter && languageQueryParameter !== i18n.locale.value
  );
});

useHead(
  computed(() => {
    // Don't include i18n metadata on mixed-language pages.
    // (We should also not index those pages, but that is handed by `useRobotsRule` in the relevant page components.)
    const i18nLink = isMixedLanguagePage.value ? [] : i18nHead.value.link || [];
    const i18nMeta = isMixedLanguagePage.value ? [] : i18nHead.value.meta || [];

    return {
      htmlAttrs: {
        lang: i18nHead.value.htmlAttrs!.lang,
      },
      link: i18nLink,
      meta: [
        ...i18nMeta,
        { name: "description", content: "Bible exposition and application" },
      ],
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: runtimeConfig.public.site.url,
            logo: `${runtimeConfig.public.site.url}/img/icons/android-chrome-maskable-512x512.png?v=1`,
          }),
        },
      ],
    };
  }),
);
</script>
