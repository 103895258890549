import { ref } from "vue";

export function useRefreshableGetter<T>(getter: () => T): {
  getter(): T;
  refresh(): void;
} {
  const refreshKey = ref(0);

  return {
    getter() {
      // noinspection JSUnusedLocalSymbols
      const _ = refreshKey.value;
      return getter();
    },
    refresh() {
      refreshKey.value++;
    },
  };
}
