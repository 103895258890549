import { defineStore } from "pinia";
import { ref } from "vue";

interface ToastPayload {
  /**
   * The message to show.
   */
  text: string;

  /**
   * How long to show the message, in milliseconds.
   */
  duration?: number;

  /**
   * Actions to show on the toast.
   */
  actions?: ToastAction[];
}

interface ToastAction {
  text: string;
  icon?: string;
  onClick: () => void;
}

/**
 * A pinia store for the global toast snackbar. Yummy.
 */
export const useToastStore = defineStore("toast", () => {
  const getInit = () => ({
    visible: false,
    text: "",
    actions: [],
  });
  const init = getInit();

  const visible = ref<boolean>(init.visible);
  const text = ref<string>(init.text);
  const actions = ref<ToastAction[]>([]);
  const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

  function $reset() {
    const init = getInit();
    visible.value = init.visible;
    text.value = init.text;
    actions.value = init.actions;
  }

  /**
   * Display a toast message.
   */
  function toast(payload: ToastPayload) {
    // First clear the old one if it's still visible:
    if (timeout.value) {
      clearTimeout(timeout.value);
      timeout.value = null;
    }
    visible.value = false;

    // Show the new one.
    text.value = payload.text;
    actions.value = payload.actions ?? [];
    visible.value = true;

    // Hide it after the duration, or persist if the duration is 0.
    const duration = payload.duration || 3000;
    if (duration > 0) {
      timeout.value = setTimeout(() => {
        visible.value = false;
      }, duration);
    }
  }

  return { toast, visible, text, actions, $reset };
});

export type ToastStore = ReturnType<typeof useToastStore>;
