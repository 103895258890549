/**
 * Find the symmetric difference between two arrays.
 *
 * Based on https://jsfiddle.net/hL9hgynq/
 */
export function diffArray<T>(arr1: T[], arr2: T[]): T[] {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  const arr: T[] = [];

  set1.forEach(function (val) {
    if (!set2.has(val)) {
      arr.push(val);
    }
  });
  set2.forEach(function (val) {
    if (!set1.has(val)) {
      arr.push(val);
    }
  });

  return arr;
}

/**
 * Zip two or more arrays.
 *
 * Based on https://gist.github.com/renaudtertrais/25fc5a2e64fe5d0e86894094c6989e10
 *
 * Examples:
 * const a = ;
 * const b = ;
 *
 * // Zip
 * zip([1, 2, 3], [4, 5, 6]);
 * [[1, 4], [2, 5], [3, 6]]
 *
 * // Unzip
 * zip(...[[1, 4], [2, 5], [3, 6]]);
 * [[1, 2, 3], [4, 5, 6]]
 *
 * @param array1
 *   The first array to iterate over.
 * @param arrays
 *   More arrays to iterate over.
 *
 * @returns
 *   A zipped array. The length is determined by the length of `array1`.
 */
export function zip(array1: any[], ...arrays: any[][]): any[][] {
  return array1.map((val, i) =>
    arrays.reduce((a, arr) => [...a, arr[i]], [val]),
  );
}

/**
 * Zip two or more arrays and pass each resulting array through a callback.
 *
 * @param callback
 *   A function that takes arguments (index, ...values) and returns anything.
 * @param array1
 *   The first array to iterate over.
 * @param arrays
 *   More arrays to iterate over.
 *
 * @returns
 *   A zipped array, mapped through the callback function. The length is determined by the length of `array1`.
 */
export function zipMap(
  callback: (index: number, ...values: any[]) => any,
  array1: any[],
  ...arrays: any[][]
): any[] {
  return array1.map((val, i) => {
    const values = arrays.reduce((a, arr) => [...a, arr[i]], [val]);
    return callback(i, ...values);
  });
}

/**
 * Callback to filter duplicates out of an array.
 *
 * Usage: `arr.filter(distinct)`
 *
 * @param value
 *   See Array.prototype.filter
 * @param index
 *   See Array.prototype.filter
 * @param arr
 *   See Array.prototype.filter
 *
 * @returns
 *   True when arr[index] is the first occurrence of `value` in `arr`.
 */
export function distinct<T>(value: T, index: number, arr: T[]): boolean {
  return arr.indexOf(value) === index;
}

/**
 * Like Array.prototype.filter, but modifies the array in place.
 *
 * Taken from https://stackoverflow.com/a/37319954/836995
 */
export function filterInPlace<TElement>(
  a: TElement[],
  condition: (e: TElement, i: number, a: TElement[]) => boolean,
): typeof a {
  let j = 0;

  a.forEach((e, i) => {
    if (condition(e, i, a)) {
      if (i !== j) a[j] = e;
      j++;
    }
  });

  a.length = j;
  return a;
}

/**
 * Return a copy of the given array, with the value appended at the end if it does not already contain the value.
 */
export function ensureArrayIncludes<T>(arr: T[], value: T): T[] {
  return arr.includes(value) ? [...arr] : [...arr, value];
}
