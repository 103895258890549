
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr"
]

export const localeLoaders = {
  "en": [],
  "fr": []
}

export const vueI18nConfigs = [
  () => import("../i18n/config.ts?hash=4caee762&config=1" /* webpackChunkName: "__i18n_config_ts_4caee762" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/config.ts",
  "locales": [
    {
      "code": "en",
      "language": "en",
      "name": "English",
      "isCatchallLocale": true,
      "defaultTid": "esv"
    },
    {
      "code": "fr",
      "language": "fr",
      "name": "Français",
      "defaultTid": "frc"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": true,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "dev": {
      "fr": "/dev"
    },
    "scripture-index": {
      "fr": "/index-biblique"
    },
    "scripture-index/[bid]": {
      "fr": "/index-biblique/[bid]"
    },
    "bible": {
      "fr": "/bible"
    },
    "bible/search": {
      "fr": "/bible/recherche"
    },
    "bible/[vids]": {
      "fr": "/bible/[vids]"
    },
    "lists": {
      "fr": "/listes"
    },
    "list/[name]/read": {
      "fr": "/liste/[name]/lire"
    },
    "list/[name]/view": {
      "fr": "/liste/[name]/voir"
    },
    "comment/[nid]": {
      "fr": "/commentaire/[nid]"
    },
    "news/[nid]": {
      "fr": "/nouvelles/[nid]"
    },
    "commentary": {
      "fr": "/commentaires"
    },
    "commentary/[vids]": {
      "fr": "/commentaires/[vids]"
    },
    "commentary/[vids]/[type]": {
      "fr": "/commentaires/[vids]/[type]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en",
    "name": "English",
    "isCatchallLocale": true,
    "defaultTid": "esv",
    "files": []
  },
  {
    "code": "fr",
    "language": "fr",
    "name": "Français",
    "defaultTid": "frc",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
