import type { InjectionKey } from "vue";
import type { SolrClientInterface } from "@rsc/vv-solr-client";
import type { ScriptureClientInterface } from "@rsc/scripture-client";
import type { FetchApiWrapper } from "~/plugins/clients/FetchApiWrapper";
import type { Logger } from "~/logging/Logger";

export const FetchApiWrapperKey: InjectionKey<FetchApiWrapper> =
  Symbol("fetchApiWrapper");

export const ScriptureClientKey: InjectionKey<ScriptureClientInterface> =
  Symbol("scriptureClient");

export const SolrClientKey: InjectionKey<SolrClientInterface> =
  Symbol("solrClient");

export const LoggerKey: InjectionKey<Logger> = Symbol("logger");
