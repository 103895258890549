import { defineStore } from "pinia";
import { type MaybeRef, ref } from "vue";
import type { VidRange } from "@rsc/scripture-util";
import { parseVidsString } from "@rsc/scripture-util";
import { availableTids } from "~/static/translations";
import { commaSeparatedStringToArray } from "~/stores/util";
import { never } from "~/never";

/**
 * A pinia store for the bible view.
 *
 * Only store things here which are also represented in the URL.
 */
export const useBibleStore = defineStore(
  "bible",
  () => {
    /**
     * The displayed VID ranges, represented as strings like "10-20,30-40".
     */
    const tids = ref<string>();

    /**
     * The IDs of open translations, represented as a string like "esv,niv".
     */
    const vids = ref<string>();

    function $reset() {
      tids.value = undefined;
      vids.value = undefined;
    }

    /**
     * Set the open translations.
     *
     * - It sets the open translations.
     * - It removes unavailable translations from the list before updating.
     * - It ignores the update if all open translations are unavailable.
     * - It ignores the update when trying to close all translations.
     */
    function setOpenTranslations(newTids: string[]) {
      // Remove unavailable translations.
      newTids = newTids.filter((newTid) => availableTids.includes(newTid));

      if (!newTids.length) {
        // Ignore the action instead of closing all translations.
        return;
      }

      tids.value = newTids.join(",");
    }

    /**
     * Get the open translations.
     *
     * - It omits any unavailable translations that may be in the store.
     * - It adds the default translation if all stored translations are unavailable.
     */
    function getOpenTranslations(defaultTid: MaybeRef<string>): string[] {
      const currentTids = commaSeparatedStringToArray(tids.value ?? "").filter(
        (tid) => availableTids.includes(tid),
      );
      return currentTids.length ? currentTids : [unref(defaultTid)];
    }

    /**
     * Get the VID range.
     */
    function getDisplayedRange(defaultVids: MaybeRef<string>): VidRange {
      return (
        parseVidsString(vids.value ?? unref(defaultVids), null)[0] ??
        never("Failed to get the VID range.")
      );
    }

    /**
     * Update the VID range, ignoring the translation.
     */
    function setDisplayedRange(range: VidRange) {
      vids.value = range.vidsString;
    }

    function getRouteState(
      defaultVids: MaybeRef<string>,
      defaultTid: MaybeRef<string>,
    ): BibleStoreRouteState {
      return {
        vids: vids.value ?? unref(defaultVids),
        tids: tids.value ?? unref(defaultTid),
      };
    }

    function setRouteState(newState: BibleStoreRouteState): void {
      // Prevent unnecessary updates.
      const oldState: Partial<BibleStoreRouteState> = {
        vids: vids.value,
        tids: tids.value,
      };

      if (
        !bibleStateIsReady(oldState) ||
        bibleStateHasChanged(newState, oldState)
      ) {
        tids.value = newState.tids;
        vids.value = newState.vids;
      }
    }

    return {
      tids,
      vids,

      $reset,

      getRouteState,
      setRouteState,

      getOpenTranslations,
      setOpenTranslations,

      getDisplayedRange,
      setDisplayedRange,
    };
  },
  {
    // Persist the bible store, but not the bible search store.
    // Use cookies for the bible store because it affects SSR markup, e.g.,
    // - "Bible" button in the bottom navigation.
    persist: {
      // IMPORTANT: When using cookie persistence, we should explicitly allow the cookie through CloudFront.
      // See CloudFront → Policies → Cache → VV-CachingOptimized-WithCookies-WithQuery → Edit cache policy → Cache key settings
      storage: persistedState.cookiesWithOptions({
        sameSite: "strict",
      }),
    },
  },
);

export interface BibleStoreRouteState {
  /**
   * The displayed VID ranges, represented as strings like "10-20,30-40".
   */
  tids: string;

  /**
   * The IDs of open translations, represented as a string like "esv,niv".
   */
  vids: string;
}

export type BibleStore = ReturnType<typeof useBibleStore>;

export function bibleStateHasChanged(
  to: BibleStoreRouteState,
  from: BibleStoreRouteState,
): boolean {
  return to.tids !== from.tids || to.vids !== from.vids;
}

export function bibleStateIsReady(
  state: Partial<BibleStoreRouteState>,
): state is BibleStoreRouteState {
  return state.tids !== undefined && state.vids !== undefined;
}
