import type { Dict } from "~/types";

/**
 * Check if any keys in dict `a` have different values than their counterparts in dict `b`.
 * Values in dict `b` which are not in dict `a` are ignored.
 *
 * Does not perform recursion through nested dicts.
 */
export function existingDictItemsChanged(a: Dict<any>, b: Dict<any>): boolean {
  return Object.keys(a).some((key) => {
    return a[key] !== b[key];
  });
}

/**
 * Check if dict `b` has any keys not in dict `a`.
 *
 * Does not perform recursion through nested dicts.
 */
export function dictItemsAdded(a: Dict<any>, b: Dict<any>): boolean {
  return Object.keys(b).some((key) => !Object.keys(a).includes(key));
}

/**
 * Check if dicts `a` and `b` have any differences.
 *
 * Does not perform recursion through nested dicts.
 */
export function dictsDiffer(a: Dict<any>, b: Dict<any>): boolean {
  return existingDictItemsChanged(a, b) || existingDictItemsChanged(b, a);
}
