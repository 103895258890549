import { inject, provide } from "vue";

/**
 * A composition function that provides a heading level to the current component and its children.
 */
export function useHeadingLevel(overrideLevel?: number): {
  current: number;
  next: number;
} {
  const current = inject<number>("headingLevel", 1);
  const next = overrideLevel ?? current + 1;
  provide("headingLevel", next);
  return { current, next };
}
