<template lang="pug">
component(:is="tagName")
  slot
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useHeadingLevel } from "../composables/headingLevel";

interface Props {
  /**
   * The relative heading level.
   */
  hLevel?: number;
}

const props = withDefaults(defineProps<Props>(), {
  hLevel: 1,
});

const headingLevel = useHeadingLevel(props.hLevel);

const tagName = computed(() => {
  const start = Math.max(headingLevel.current - 1, 0);
  const newLevel = Math.min(Math.max(start + props.hLevel, 1), 6);

  return `h${newLevel}`;
});
</script>
