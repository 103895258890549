<template lang="pug">
VSnackbar(
  v-model="toastStore.visible"
  color="secondary"
) {{ toastStore.text }}
  template(#actions)
    VBtn(
      v-for="(action, i) in toastStore.actions"
      :key="i"
      :icon="!!action.icon"
      :title="action.text"
      :aria-label="action.text"
      @click="action.onClick(); toastStore.visible = false"
    )
      VIcon(v-if="action.icon") {{ action.icon }}
      template(v-else) {{ action.text }}

    VBtn(
      :icon="true"
      title="close"
      aria-label="close"
      @click="toastStore.visible = false"
    )
      VIcon {{ mdiClose }}
</template>

<script setup lang="ts">
import { mdiClose } from "@mdi/js";
import { useToastStore } from "~/stores/useToastStore";

const toastStore = useToastStore();
</script>
