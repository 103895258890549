export const bibleFilled =
  "M6 2a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H6zm5 4h2v3h3v2h-3v7h-2v-7H8V9h3V6z";
export const bibleOutline =
  "M6 2c-1.099 0-2 .901-2 2v16c0 1.099.901 2 2 2h12c1.099 0 2-.901 2-2V4c0-1.099-.901-2-2-2H6zm0 1h12c.558 0 1 .442 1 1v16c0 .558-.442 1-1 1H6c-.558 0-1-.442-1-1V4c0-.558.442-1 1-1zm5 3v3H8v2h3v7h2v-7h3V9h-3V6h-2z";
export const commentaryFilled =
  "M20.41 8.41l-4.83-4.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.42zM7 7h7v2H7V7zm10 10H7v-2h10v2zm0-4H7v-2h10v2z";
export const commentaryOutline =
  "M5 3c-1.097 0-2 .903-2 2v14c0 1.097.903 2 2 2h14c1.097 0 2-.903 2-2V9.83c0-.529-.21-1.04-.59-1.42l-4.83-4.83c-.37-.37-.879-.58-1.41-.58H5zm0 1h9.17c.267 0 .524.108.703.287l4.83 4.83c.194.194.297.448.297.713V19c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1zm2 3v2h7V7H7zm0 4v2h10v-2H7zm0 4v2h10v-2H7z";

export const applicationOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.777V9.59a2.23 2.23 0 0 0-.656-1.578l-5.365-5.367A2.224 2.224 0 0 0 14.41 2H4.223zm0 1.111H14.41c.298 0 .581.119.781.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .609-.503 1.112-1.112 1.112H4.223a1.118 1.118 0 0 1-1.112-1.112V4.223c0-.609.503-1.112 1.112-1.112zM8.11 5.89a.553.553 0 0 0-.392.162L5.36 8.408a.555.555 0 1 0 .785.785l1.41-1.408v6.992a.556.556 0 1 0 1.11 0V7.785l1.41 1.408a.555.555 0 1 0 .785-.785L8.504 6.051a.553.553 0 0 0-.393-.162zm8.2 7.222a.555.555 0 0 0-.395.947l1.41 1.41h-6.992a.556.556 0 1 0 0 1.112h6.992l-1.41 1.408a.555.555 0 1 0 .787.785l2.356-2.357a.555.555 0 0 0 0-.785l-2.356-2.358a.553.553 0 0 0-.392-.162z";
export const christocentricOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.78V9.59c0-.59-.233-1.156-.656-1.578l-5.365-5.367A2.22 2.22 0 0 0 14.412 2H4.222zM4.22 3.111h10.19c.296 0 .58.119.78.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .608-.504 1.112-1.112 1.112h-1.256c-.633-2.4-3.277-4.24-6.521-4.428v-5.572h2.777a.556.556 0 0 0 0-1.112H12V7a.556.556 0 1 0-1.111 0v2.777h-2.78a.556.556 0 1 0 0 1.112h2.78v5.572c-3.244.19-5.89 2.028-6.524 4.428h-.144a1.118 1.118 0 0 1-1.11-1.112V4.223c0-.608.502-1.112 1.11-1.112zm7.224 14.446c1.77 0 3.331.553 4.428 1.396.726.56 1.23 1.226 1.486 1.938H5.53c.256-.712.76-1.379 1.487-1.938 1.097-.843 2.658-1.396 4.43-1.396z";
export const contextOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.777V9.59a2.23 2.23 0 0 0-.656-1.578l-5.365-5.367A2.224 2.224 0 0 0 14.41 2H4.223zm0 1.111H14.41c.298 0 .581.119.781.319l5.368 5.367c.214.214.33.499.33.793v1.394a1.667 1.667 0 1 0 0 3.143v5.65c0 .609-.503 1.112-1.112 1.112H16.35a1.669 1.669 0 0 0-1.572-2.223 1.667 1.667 0 0 0-1.573 2.223H4.223a1.118 1.118 0 0 1-1.112-1.112v-2.316a1.667 1.667 0 1 0 0-3.145V4.223c0-.609.503-1.112 1.112-1.112zM7 5.334a1.666 1.666 0 1 0 0 3.331 1.666 1.666 0 0 0 0-3.331zm0 1.111a.554.554 0 1 1 .001 1.108A.554.554 0 0 1 7 6.445zM11.445 12a1.667 1.667 0 1 0-.001 3.335A1.667 1.667 0 0 0 11.445 12zm8.889 0a.556.556 0 1 1-.002 1.111.556.556 0 0 1 .002-1.111zm-8.889 1.111a.556.556 0 1 1-.002 1.111.556.556 0 0 1 .002-1.11zm-7.779 2.223a.556.556 0 1 1 .002 1.112.556.556 0 0 1-.002-1.112zm11.111 4.443a.556.556 0 1 1 .002 1.111.556.556 0 0 1-.002-1.11z";
export const discussionOutline =
  "M9.604 10.604C8.924 11.284 8.5 12.163 8.5 13h-1c0-1.163.576-2.283 1.396-3.104C9.716 9.076 10.837 8.5 12 8.5s2.283.576 3.104 1.396c.82.82 1.396 1.941 1.396 3.104 0 1.593-.994 2.697-1.895 3.7l-.231.257C13.385 18.07 12.5 19.197 12.5 21h-1c0-2.197 1.115-3.57 2.126-4.707l.185-.207C14.768 15.013 15.5 14.194 15.5 13c0-.837-.424-1.717-1.104-2.396C13.716 9.924 12.837 9.5 12 9.5s-1.717.424-2.396 1.104Zm6.374-7.96 5.366 5.367C21.767 8.433 22 9 22 9.59v10.189C22 21 21 22 19.778 22H4.222C3 22 2 21 2 19.778V4.222C2 3 3 2 4.222 2h10.19a2.22 2.22 0 0 1 1.566.644Zm-.786.786c-.2-.2-.483-.319-.78-.319H4.221c-.608 0-1.11.503-1.11 1.111v15.556c0 .608.502 1.11 1.11 1.11h15.556c.608 0 1.11-.502 1.11-1.11V9.588c0-.293-.115-.577-.33-.791z";
export const expositionOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.777V9.59a2.23 2.23 0 0 0-.656-1.578l-5.365-5.367A2.224 2.224 0 0 0 14.41 2H4.223zm0 1.111H14.41c.298 0 .581.119.781.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .609-.503 1.112-1.112 1.112H4.223a1.118 1.118 0 0 1-1.112-1.112V4.223c0-.609.503-1.112 1.112-1.112zM12 8.666a3.335 3.335 0 0 0-2.717 5.266l-2.12 2.119a.557.557 0 0 0 .786.787l2.12-2.121A3.334 3.334 0 1 0 12 8.667zm0 1.111a2.223 2.223 0 1 1 0 4.446 2.223 2.223 0 0 1 0-4.446z";
export const introductionOutline =
  "M12 14.051a1.051 1.051 0 1 1 0-2.102 1.051 1.051 0 0 1 0 2.102zm0 1a2.051 2.051 0 1 0 0-4.102 2.051 2.051 0 0 0 0 4.102zm0 2.077a4.128 4.128 0 1 1 0-8.256 4.128 4.128 0 0 1 0 8.256zm0 1a5.128 5.128 0 1 0 0-10.256 5.128 5.128 0 0 0 0 10.256zm8.637-9.41c.235.235.363.548.363.87v10.19c0 .67-.552 1.222-1.222 1.222H4.222C3.552 21 3 20.448 3 19.778V4.222C3 3.552 3.552 3 4.222 3h10.19c.326 0 .637.13.859.352zm.707-.707-5.366-5.367A2.22 2.22 0 0 0 14.41 2H4.222C3 2 2 3 2 4.222v15.556C2 21 3 22 4.222 22h15.556C21 22 22 21 22 19.778V9.588c0-.588-.233-1.155-.656-1.577Z";
export const illustrationsOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.777V9.59a2.23 2.23 0 0 0-.656-1.578l-5.365-5.367A2.224 2.224 0 0 0 14.41 2H4.223zm0 1.111H14.41c.298 0 .581.119.781.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .114-.017.225-.05.328L8.64 9.361a.555.555 0 0 0-.701-.027L3.11 12.971V4.223c0-.609.503-1.112 1.112-1.112zm10 3.334a2.221 2.221 0 1 0 0 4.444 2.221 2.221 0 1 0 0-4.443zm0 1.11a1.111 1.111 0 1 1 0 2.223 1.111 1.111 0 0 1 0-2.223zm-5.979 2.94L20.018 20.86a1.096 1.096 0 0 1-.24.028H4.222a1.118 1.118 0 0 1-1.112-1.112v-5.554a.552.552 0 0 0 .334-.112l4.8-3.617z";
export const sermonOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.777V9.59a2.23 2.23 0 0 0-.656-1.578l-5.365-5.367A2.224 2.224 0 0 0 14.41 2H4.223zm0 1.111H14.41c.298 0 .581.119.781.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .609-.503 1.112-1.112 1.112H4.223a1.118 1.118 0 0 1-1.112-1.112V4.223c0-.609.503-1.112 1.112-1.112zm2.222 6.666c-.613 0-1.111.498-1.111 1.112v3.334c0 .613.498 1.111 1.111 1.111h6.205l1.897 1.896a1.11 1.11 0 0 0 1.898-.785v-1.111h1.11c.613 0 1.111-.498 1.111-1.111v-3.334c0-.614-.498-1.112-1.111-1.112H6.445zm0 1.112h11.11v3.334h-2.221v2.222l-2.223-2.222H6.445v-3.334z";
export const structureOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.777V9.59a2.23 2.23 0 0 0-.656-1.578l-5.365-5.367A2.224 2.224 0 0 0 14.41 2H4.223zm0 1.111H14.41c.298 0 .581.119.781.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .609-.503 1.112-1.112 1.112H4.223a1.118 1.118 0 0 1-1.112-1.112V4.223c0-.609.503-1.112 1.112-1.112zm3.332 5.555a1.11 1.11 0 0 0-1.11 1.111v6.668c0 .614.496 1.11 1.11 1.11h8.89c.614 0 1.11-.496 1.11-1.11V9.777a1.11 1.11 0 0 0-1.11-1.111h-8.89zm0 1.111h8.89v6.668h-8.89V9.777z";
export const technicalOutline =
  "M5.1 6.734a.967.967 0 0 1 .963-.834h3.84c.478 0 .9.34.974.834l.001.004.32 2.266c.44.2.848.44 1.237.716l2.143-.862.015-.004c.409-.137.926-.01 1.18.443l1.924 3.329.004.008a.996.996 0 0 1-.25 1.236l-.004.004-1.802 1.408c.025.234.038.474.038.718 0 .246-.013.485-.034.715l1.784 1.41c.4.304.483.832.251 1.25l-.004.007-1.368 2.368-.866-.5 1.357-2.349-2.21-1.746.033-.279c.035-.285.057-.577.057-.876 0-.3-.022-.585-.066-.857l-.046-.291 2.244-1.753-1.904-3.296-2.625 1.055-.226-.172a6.536 6.536 0 0 0-1.506-.871l-.266-.109L9.891 6.9H6.087L5.69 9.706l-.266.109a6.718 6.718 0 0 0-1.508.872l-.225.17-1.457-.585.373-.928.937.377c.383-.27.794-.514 1.235-.716ZM7.983 12.9a3.11 3.11 0 0 0-3.1 3.1 3.11 3.11 0 0 0 3.1 3.1 3.11 3.11 0 0 0 3.1-3.1 3.11 3.11 0 0 0-3.1-3.1zm-4.1 3.1a4.11 4.11 0 0 1 4.1-4.1 4.11 4.11 0 0 1 4.1 4.1 4.11 4.11 0 0 1-4.1 4.1 4.11 4.11 0 0 1-4.1-4.1zM15.978 2.644l5.366 5.367C21.767 8.433 22 9 22 9.59v10.189C22 21 21 22 19.778 22H4.222C3 22 2 21 2 19.778V4.222C2 3 3 2 4.222 2h10.19a2.22 2.22 0 0 1 1.566.644Zm-.786.786c-.2-.2-.483-.319-.78-.319H4.221c-.608 0-1.11.503-1.11 1.111v15.556c0 .608.502 1.11 1.11 1.11h15.556c.608 0 1.11-.502 1.11-1.11V9.588c0-.293-.115-.577-.33-.791z";
export const timelineOutline =
  "M16.5 13a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5Zm-5-4a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 .5-.5Zm-5 2a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0v-6a.5.5 0 0 1 .5-.5Zm9.478-8.356 5.366 5.367C21.767 8.433 22 9 22 9.59v10.189C22 21 21 22 19.778 22H4.222C3 22 2 21 2 19.778V4.222C2 3 3 2 4.222 2h10.19a2.22 2.22 0 0 1 1.566.644Zm-.786.786c-.2-.2-.483-.319-.78-.319H4.221c-.608 0-1.11.503-1.11 1.111v15.556c0 .608.502 1.11 1.11 1.11h15.556c.608 0 1.11-.502 1.11-1.11V9.588c0-.293-.115-.577-.33-.791z";
export const themesOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.78V9.59c0-.59-.233-1.156-.656-1.578l-5.365-5.367A2.22 2.22 0 0 0 14.412 2H4.222zM4.22 3.111h10.19c.296 0 .58.119.78.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .608-.504 1.112-1.112 1.112H4.223a1.118 1.118 0 0 1-1.112-1.112v-3.334h11.666a.555.555 0 1 0 0-1.109H3.11v-2.225h8.334a.556.556 0 1 0 0-1.111H3.111v-2.22h10.557a.556.556 0 0 0 0-1.11H3.109V4.223c0-.608.504-1.112 1.112-1.112z";
export const unassignedOutline =
  "M4.223 2A2.23 2.23 0 0 0 2 4.223v15.554C2 21 3 22 4.223 22h15.554C21 22 22 21 22 19.78V9.59c0-.59-.233-1.156-.656-1.578l-5.365-5.367A2.221 2.221 0 0 0 14.412 2H4.222zm0 1.111h10.19c.296 0 .578.119.778.319l5.368 5.367c.214.214.33.499.33.793v10.187c0 .608-.504 1.112-1.112 1.112H4.223a1.12 1.12 0 0 1-1.112-1.112V4.223c0-.608.504-1.112 1.112-1.112z";
