import { default as indexuEr8wsYUuQMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/index.vue?macro=true";
import { default as _91vids_93QJmDD65XYuMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/bible/[vids].vue?macro=true";
import { default as indexfMlY2OZ1NJMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/bible/index.vue?macro=true";
import { default as search6YPEl2yMurMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/bible/search.vue?macro=true";
import { default as _91nid_93iuocpyrH4hMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/comment/[nid].vue?macro=true";
import { default as _91type_93nmr3HKnRqEMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/commentary/[vids]/[type].vue?macro=true";
import { default as indexUj8lpNeDEKMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/commentary/[vids]/index.vue?macro=true";
import { default as index2kgCnxt19IMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/commentary/index.vue?macro=true";
import { default as devniFzcqhSOgMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/dev.vue?macro=true";
import { default as readPgRZXHeDO2Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/list/[name]/read.vue?macro=true";
import { default as viewnSjKJvRkBFMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/list/[name]/view.vue?macro=true";
import { default as listsyjthaglK94Meta } from "/opt/atlassian/pipelines/agent/build/app/pages/lists.vue?macro=true";
import { default as _91nid_93SrQJsCUrccMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/news/[nid].vue?macro=true";
import { default as _91bid_93c4uCkOaczDMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/scripture-index/[bid].vue?macro=true";
import { default as indexYtHbori6WvMeta } from "/opt/atlassian/pipelines/agent/build/app/pages/scripture-index/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "bible-vids___en",
    path: "/en/bible/:vids()",
    meta: _91vids_93QJmDD65XYuMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/bible/[vids].vue").then(m => m.default || m)
  },
  {
    name: "bible-vids___fr",
    path: "/fr/bible/:vids()",
    meta: _91vids_93QJmDD65XYuMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/bible/[vids].vue").then(m => m.default || m)
  },
  {
    name: "bible___en",
    path: "/en/bible",
    meta: indexfMlY2OZ1NJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/bible/index.vue").then(m => m.default || m)
  },
  {
    name: "bible___fr",
    path: "/fr/bible",
    meta: indexfMlY2OZ1NJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/bible/index.vue").then(m => m.default || m)
  },
  {
    name: "bible-search___en",
    path: "/en/bible/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/bible/search.vue").then(m => m.default || m)
  },
  {
    name: "bible-search___fr",
    path: "/fr/bible/recherche",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/bible/search.vue").then(m => m.default || m)
  },
  {
    name: "comment-nid___en",
    path: "/en/comment/:nid()",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/comment/[nid].vue").then(m => m.default || m)
  },
  {
    name: "comment-nid___fr",
    path: "/fr/commentaire/:nid()",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/comment/[nid].vue").then(m => m.default || m)
  },
  {
    name: "commentary-vids-type___en",
    path: "/en/commentary/:vids()/:type()",
    meta: _91type_93nmr3HKnRqEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/commentary/[vids]/[type].vue").then(m => m.default || m)
  },
  {
    name: "commentary-vids-type___fr",
    path: "/fr/commentaires/:vids()/:type()",
    meta: _91type_93nmr3HKnRqEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/commentary/[vids]/[type].vue").then(m => m.default || m)
  },
  {
    name: "commentary-vids___en",
    path: "/en/commentary/:vids()",
    meta: indexUj8lpNeDEKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/commentary/[vids]/index.vue").then(m => m.default || m)
  },
  {
    name: "commentary-vids___fr",
    path: "/fr/commentary/:vids()",
    meta: indexUj8lpNeDEKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/commentary/[vids]/index.vue").then(m => m.default || m)
  },
  {
    name: "commentary___en",
    path: "/en/commentary",
    meta: index2kgCnxt19IMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/commentary/index.vue").then(m => m.default || m)
  },
  {
    name: "commentary___fr",
    path: "/fr/commentary",
    meta: index2kgCnxt19IMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/commentary/index.vue").then(m => m.default || m)
  },
  {
    name: "dev___en",
    path: "/en/dev",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/dev.vue").then(m => m.default || m)
  },
  {
    name: "dev___fr",
    path: "/fr/dev",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/dev.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "list-name-read___en",
    path: "/en/list/:name()/read",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/list/[name]/read.vue").then(m => m.default || m)
  },
  {
    name: "list-name-read___fr",
    path: "/fr/liste/:name()/lire",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/list/[name]/read.vue").then(m => m.default || m)
  },
  {
    name: "list-name-view___en",
    path: "/en/list/:name()/view",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/list/[name]/view.vue").then(m => m.default || m)
  },
  {
    name: "list-name-view___fr",
    path: "/fr/liste/:name()/voir",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/list/[name]/view.vue").then(m => m.default || m)
  },
  {
    name: "lists___en",
    path: "/en/lists",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/lists.vue").then(m => m.default || m)
  },
  {
    name: "lists___fr",
    path: "/fr/listes",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/lists.vue").then(m => m.default || m)
  },
  {
    name: "news-nid___en",
    path: "/en/news/:nid()",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/news/[nid].vue").then(m => m.default || m)
  },
  {
    name: "news-nid___fr",
    path: "/fr/nouvelles/:nid()",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/news/[nid].vue").then(m => m.default || m)
  },
  {
    name: "scripture-index-bid___en",
    path: "/en/scripture-index/:bid()",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/scripture-index/[bid].vue").then(m => m.default || m)
  },
  {
    name: "scripture-index-bid___fr",
    path: "/fr/index-biblique/:bid()",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/scripture-index/[bid].vue").then(m => m.default || m)
  },
  {
    name: "scripture-index___en",
    path: "/en/scripture-index",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/scripture-index/index.vue").then(m => m.default || m)
  },
  {
    name: "scripture-index___fr",
    path: "/fr/scripture-index",
    component: () => import("/opt/atlassian/pipelines/agent/build/app/pages/scripture-index/index.vue").then(m => m.default || m)
  }
]